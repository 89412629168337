<template>
  <div class="mainBody">
    <el-row :gutter="10" class="rowForm">
      <el-col :span="colSpanLeft">
        <div class="leftBody">
          <el-row @click.self="handleClick">
            <div class="topNav">
              <div class="topleft">
                <el-tag class="keyTags" v-if="goodsCart.clientName===undefined" type="warning">请选择客户</el-tag>
                <el-tag class="keyTags" id="clientIds" v-show="goodsCart.clientName.length>0"
                        :key="index"
                        v-for="(tag,index) in goodsCart.clientName"
                        :disable-transitions="false"
                        @close="handleClose(tag)"
                >
                  <template>
                    <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(tag)"
                                :disabled="!remarkFlag">
                      <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                        {{tag}}
                      </div>
                      <span class="showRemarkLine">{{tag}}</span>
                    </el-tooltip>
                  </template>
                </el-tag>
                <el-button class="keyTagsInput" icon="el-icon-circle-plus-outline" type="primary"
                           @click="showChooseDialog">
                  选择客户
                </el-button>
              </div>
              <div class="topRight">
                <div class="tipTag">
                  <div class="tagBox">
                    <el-image class="tagImg"
                              :src="this.isPesticide===2?imgUrls.recipe_rec:imgUrls.recipe_sale"></el-image>
                    <span class="tagTitle">{{this.isPesticide===2 && (!this.isHLJ || (this.isHLJ && this.isDN))?'销货开单':(this.isPesticide===1 || this.isPesticide===3 || this.isPesticide===4) && (!this.isHLJ || (this.isHLJ && this.isDN))?'处方开单':this.isHLJ && !this.isDN && this.hljMode===1?'农药开单':'其他商品开单'}}</span>
                  </div>
                </div>
              </div>

            </div>
          </el-row>

          <div class="toastMessage" @click="handleClick">
            <el-alert title="提示：点击这里，使用扫码枪添加商品" type="success" :closable="false" center show-icon>
            </el-alert>
            <input type="text" id="scanCode" class="scanCode" v-model="qrcode" @input="qrCodeInput"/>
          </div>
          <div class="mainForm" @click.self="handleClick">
            <el-table
              ref="multipleTable"
              tooltip-effect="dark"
              :data="goodsCart.details"
              height="420"
              border
              highlight-current-row
              :empty-text="isHLJ && !isDN && hljMode===1?'未选择任何商品，请扫码添加商品':'未选择任何商品，请在右侧栏选择添加商品'"
              class="previous-row" :class="showRange?'headerHight':''"
              :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
            >
              <el-table-column type="index" label="序号" class="indexCenter" :width="49"></el-table-column>
              <el-table-column property="goodsName" label="已选商品" :width="isHLJ && !isDN && hljMode===1?250: 120"
                               :show-overflow-tooltip="true"></el-table-column>
              <el-table-column property="packageUnit" label="包装规格" :width="isHLJ && !isDN && hljMode===1?180:140"
                               :show-overflow-tooltip="true"></el-table-column>
              <el-table-column v-if="showRange" property="useRange" label="施用范围" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  {{scope.row.useRange}}
                  <!--<el-tooltip class="toolTipWidthPlugin" placement="top" @mouseenter.native="rangeEnter(scope.row.useRange)" :disabled="!rangeFlag">
                    <div class="toolTipWidth" slot="content" v-show="rangeFlag">
                      {{scope.row.useRange===''?'-':scope.row.useRange}}
                    </div>
                    <span class="overFlowText">{{scope.row.useRange===''?'-':scope.row.useRange}}</span>
                  </el-tooltip>-->
                </template>
              </el-table-column>
              <el-table-column property="lastPackageLevel" label="单位" :width="isHLJ && !isDN && hljMode===1?90:49">
                <template slot-scope="scope">
                  {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                </template>
              </el-table-column>
              <el-table-column property="goodsPrice" label="销售单价" :width="isHLJ && !isDN && hljMode===1?'':105"
                               :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag type="danger" class="showCenter">
                    ￥ {{scope.row.goodsPrice}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="goodsNumber" label="销售数量" :width="isHLJ && !isDN && hljMode===1?'':105"
                               :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag :type="scope.row.buyPackageLevel===1?'primary':'danger'" class="showCenter">
                    {{scope.row.goodsNumber}}
                    {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="packageLevel" label="小计" :width="isHLJ && !isDN && hljMode===1?'':105"
                               :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag type="danger" class="showCenter">
                    ￥ {{scope.row.totalPrice}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                class="optionList"
                fixed="right"
                label="操作"
                width="145">
                <!--使用slot-scope传递当前行参数-->
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="editCart(scope.row)">修改
                  </el-button>
                  <el-button
                    size="mini"
                    type="warning"
                    @click="delCart(scope.row)">删除
                  </el-button>
                  <!--<el-button
                    size="mini"
                    type="warning"
                    @click="del">排序
                  </el-button>-->
                </template>

              </el-table-column>
            </el-table>
          </div>
          <div class="formBottom">
            <el-row>
              <div class="pay">
                <div class="payLabel">应付金额：</div>
                <el-input-number class="priceInput" v-model="goodsCart.accountsPayable" :precision="2" :step="1"
                                 disabled :min="0"
                                 :max="goodsCart.accountsPayable" placeholder="请输入应付金额"></el-input-number>
              </div>
            </el-row>
            <el-row>
              <div class="pay">
                <div class="payLabel">优惠金额：</div>
                <el-input-number class="priceInput" v-model="goodsCart.discountMoney" :precision="2" :step="1" :min="0"
                                 :max="goodsCart.accountsPayable" placeholder="请输入优惠金额"
                                 @blur="discountMoneyChange(goodsCart.discountMoney)"></el-input-number>
              </div>
            </el-row>
            <el-row>
              <div class="pay">
                <div class="payLabel">实付金额：</div>
                <el-input-number class="priceInput" v-model="goodsCart.paymentAmount" :precision="2" :step="1"
                                 :min="0" @blur="paymentAmountChange(goodsCart.paymentAmount)"
                                 :max="realPaymentMax" placeholder="请输入实付金额"></el-input-number>
              </div>
            </el-row>
            <el-row>
              <div class="payDebt">
                <div class="payLabel">赊账：</div>
                <div class="debtMoneyText" :class="goodsCart.creditMoney>0?'debtMoneyWarning':''">
                  {{goodsCart.creditMoney}} ￥
                </div>
                <div class="debtMoneyDesc">注：赊账 = 应付金额 - 优惠金额 - 实付金额</div>
              </div>
            </el-row>
            <el-row>
              <div class="payRemark">
                <div class="remarkLabel">备注：</div>
                <el-input id="stockDescription" class="inputCoreDesc" :rows="2" @input="remarkBlur"
                          type="textarea" maxlength="500" show-word-limit v-model="goodsCart.remark"
                          placeholder="请输入备注信息，最多500个字符"></el-input>
              </div>
            </el-row>
            <div class="bottomBtn">
              <el-button class="storeInfoBtn" type="danger" icon="el-icon-document-copy" @click="saveCart">去结算
              </el-button>
              <el-badge :value="goodsCart.num" class="item">
                <el-button :disabled="goodsCart.num>0?false:true" class="resetBtn" type="danger" plain
                           icon="el-icon-delete" @click="clearCart()">清空购物车
                </el-button>
              </el-badge>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="colSpanRight">

        <div class="rightBody" :class="(goodsCart.recipeId && recipeAddGoods)?'noBottom':''">
          <!--saleMode=true非农药-->
          <div v-show="saleMode">
            <el-row>
              <div class="topNavRight">
                <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                             placeholder="选择商品分类"
                             v-model="category"
                             :options="categorys"
                             :props="{value:'categoryId',label:'categoryName',children:'children'}"
                             separator=" / "
                             @change="categoryChange">
                </el-cascader>
                <el-input class="searchInput"
                          placeholder="请输入商品名称"
                          v-model="searchInput"
                          :blur="searchInputChange(searchInput)"
                          :clearable="true">
                </el-input>
                <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
              </div>
              <div class="navRight">
                <el-button v-if="getAuthorityMenu('300102') && (!isHLJ || (isHLJ && isDN))" class="searchBtn"
                           type="danger" @click="toNewGood">新增商品
                </el-button>
                <el-button class="refreshBtn" icon="el-icon-refresh" circle @click="getSearch(1)"></el-button>
              </div>
            </el-row>

            <div class="row mainCard" @click.self="handleClick">
              <el-col :span="4" v-for="(good, index) in goods" :key="index">
                <el-card class="goodCart" :class="good.added?'addGood':''">
                  <div class="imgBox">
                    <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                    <div class="innerImg" v-if="good.isGreen==1">
                      <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                    </div>

                  </div>

                  <div v-if="good.added && good.inventory>0" class="checkedbox">已选</div>
                  <div v-if="good.added && good.inventory<1" class="checkedboxPlugin">
                    <li class="inventoryWarning el-icon-warning"></li>
                    库存不足
                  </div>
                  <div class="goodDesc">
                    <div class="desc">
                      <div class="descTitle">规格</div>
                      <div class="descContent">{{ good.packageUnit }}</div>
                    </div>
                    <div class="descTag">
                      <div class="descTitle">售价</div>
                      <div class="descContentTag">
                        <el-tag type="warning" class="inventoryTag">
                          ￥&nbsp;{{good.goodsPrice}}
                        </el-tag>
                      </div>

                    </div>
                    <div class="descTag">
                      <div class="descTitle">库存</div>
                      <div class="descContentTag">
                        <el-tag :type="good.inventory <= 5 ? 'danger':'primary'" class="inventoryTag"
                                id="status">{{good.inventory}}&nbsp;{{good.middleUnitName}}
                        </el-tag>
                      </div>
                    </div>
                    <div class="desc">
                      <div class="descTitle">商品名</div>
                      <div class="descContent">{{ good.goodsName }}</div>
                    </div>

                    <div class="bottom clearfix">
                      <el-button v-if="!good.added && good.inventory>0" class="addCart" type="danger"
                                 icon="el-icon-shopping-bag-2"
                                 @click="addGood(good)">
                        添加购物车
                      </el-button>
                      <el-button v-if="good.added && good.inventory>0" disabled class="addCart" type="danger"
                                 icon="el-icon-s-goods">
                        商品已添加
                      </el-button>
                      <el-button v-if="good.inventory<1" class="addCart" type="warning" icon="el-icon-sell"
                                 @click="toStockOrder(good)">
                        去进货
                      </el-button>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </div>
          </div>
          <!--saleMode=false农药-->
          <div v-show="!saleMode">
            <!--选择添加商品-->
            <div v-show="goodsCart.recipeId && recipeAddGoods">
              <el-row>
                <div class="topNavRight">
                  <el-cascader class="categoryInput inputOptionLine" ref="recipeGoodsCategoryInput"
                               placeholder="选择商品分类"
                               v-model="category"
                               :options="categorys"
                               :props="{value:'categoryId',label:'categoryName',children:'children'}"
                               separator=" / "
                               @change="goodCategoryChange">
                  </el-cascader>
                  <el-input class="searchInput"
                            placeholder="请输入商品名称"
                            v-model="searchInput"
                            :blur="searchInputChange(searchInput)"
                            :clearable="true">
                  </el-input>
                  <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
                </div>
                <div class="navRight">
                  <el-button v-if="getAuthorityMenu('300102') && (!isHLJ || (isHLJ && isDN))" class="searchBtn"
                             type="danger" @click="toNewGood">新增商品
                  </el-button>
                  <el-button class="refreshBtn" icon="el-icon-refresh" circle @click="getSearch(1)"></el-button>
                </div>
              </el-row>
              <el-divider class="dealTip">
                <i class="glyphicon glyphicon-list-alt dealTipIcon"></i>
                <span class="dealTipTitleAdd">添加商品至处方商品</span>
              </el-divider>
              <div class="row mainCard" @click.self="handleClick">
                <el-col :span="4" v-for="(good, index) in recipeGoodsList" :key="index">
                  <el-card class="goodCart" :class="good.added?'addGood':''">
                    <div class="imgBox">
                      <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                      <div class="innerImg" v-if="good.isGreen==1"><!--v-if="good.isGreen==1"-->
                        <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                      </div>

                    </div>

                    <div v-if="good.added && good.inventory>0" class="checkedbox">已选</div>
                    <div v-if="good.added && good.inventory<1" class="checkedboxPlugin">
                      <li class="inventoryWarning el-icon-warning"></li>
                      库存不足
                    </div>
                    <div class="goodDesc">
                      <div class="desc">
                        <div class="descTitle">规格</div>
                        <div class="descContent">{{ good.packageUnit }}</div>
                      </div>
                      <div class="descTag">
                        <div class="descTitle">售价</div>
                        <div class="descContentTag">
                          <el-tag type="warning" class="inventoryTag">
                            ￥&nbsp;{{good.goodsPrice}}
                          </el-tag>
                        </div>

                      </div>
                      <div class="descTag">
                        <div class="descTitle">库存</div>
                        <div class="descContentTag">
                          <el-tag :type="good.inventory <= 5 ? 'danger':'primary'" class="inventoryTag"
                                  id="statusRecipeAddGoods">{{good.inventory}}&nbsp;{{good.middleUnitName}}
                          </el-tag>
                        </div>
                      </div>
                      <div class="desc">
                        <div class="descTitle">商品名</div>
                        <div class="descContent">{{ good.goodsName }}</div>
                      </div>

                      <div class="bottom clearfix">
                        <el-button v-if="!good.added && good.inventory>0" class="addCart" type="danger"
                                   icon="el-icon-shopping-bag-2"
                                   @click="addGood(good)">
                          添加购物车
                        </el-button>
                        <el-button v-if="good.added && good.inventory>0" disabled class="addCart" type="danger"
                                   icon="el-icon-s-goods">
                          商品已添加
                        </el-button>
                        <el-button v-if="good.inventory<1" class="addCart" type="warning" icon="el-icon-sell"
                                   @click="toStockOrder(good)">
                          去进货
                        </el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>
                <div class="selectGoodsBoxBig">
                  <div class="selectGoodsBtnBig" @click="recipeSelectGoodsReturn">
                    <i class="selectGoodsIcon glyphicon glyphicon-list-alt"></i>
                    <span class="selectGoodsTitle">返回处方商品列表</span>
                  </div>
                </div>
              </div>
            </div>

            <!--处方商品处理-->
            <div v-show="goodsCart.recipeId && !recipeAddGoods">
              <!--<el-row>
                <div class="topNavRight">
                  <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                               placeholder="选择商品分类"
                               v-model="category"
                               :options="categorys"
                               :props="{value:'categoryId',label:'categoryName',children:'children'}"
                               separator=" / "
                               @change="categoryChange">
                  </el-cascader>
                  <el-input class="searchInput"
                            placeholder="请输入商品名称"
                            v-model="searchInput"
                            :blur="searchInputChange(searchInput)"
                            :clearable="true">
                  </el-input>
                  <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
                </div>
                <div class="navRight">
                  <el-button v-if="getAuthorityMenu('300102')" class="searchBtn" type="danger" @click="toNewGood">新增商品
                  </el-button>
                  <el-button class="refreshBtn" icon="el-icon-refresh" circle @click="getSearch(1)"></el-button>
                </div>
              </el-row>-->
              <div class="dealBox">
                <el-divider class="dealTip">
                  <i class="glyphicon glyphicon-list-alt dealTipIcon"></i>
                  <span class="dealTipTitle">请处理已下架、库存不足商品再添加处方购物车</span>
                </el-divider>
              </div>

              <div class="row mainCard" @click.self="handleClick">
                <el-col :span="4" v-for="(good, index) in recipeDealGoods" :key="index">
                  <el-card class="goodCart" :class="good.added?'addGood':''">
                    <div class="imgBox">
                      <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                      <div class="innerImg" v-if="good.isGreen==1">
                        <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                      </div>

                    </div>

                    <div v-if="good.added && good.inventory>0" class="checkedbox">已选</div>
                    <div v-if="good.added && good.inventory<1" class="checkedboxPlugin">
                      <li class="inventoryWarning el-icon-warning"></li>
                      库存不足
                    </div>
                    <el-button type="danger" icon="el-icon-delete" circle class="delGoods"
                               @click="delGoods(good)"></el-button>
                    <div class="goodDesc">
                      <div class="desc">
                        <div class="descTitle">商品名</div>
                        <div class="descContent">{{ good.goodsName }}</div>
                      </div>
                      <div class="desc">
                        <div class="descTitle">规格</div>
                        <div class="descContent">{{ good.packageUnit }}</div>
                      </div>
                      <div class="descTag">
                        <div class="descTitle">状态</div>
                        <div class="descContentTag">
                          <el-tag :type="good.status===0?'primary':'warning'" class="inventoryTag">
                            &nbsp;{{GOODS_STATUS | optionObjectKV(good.status)}}
                          </el-tag>
                        </div>
                      </div>
                      <div class="desc">
                        <div class="descTitle">建议用量</div>
                        <div class="descContent">{{ good.suggestUsageCount }}</div>
                      </div>
                      <div class="descTag">
                        <div class="descTitle">库存</div>
                        <div class="descContentTag">
                          <el-tag
                            :type="(good.inventory <= 5 || good.suggestUsageCount>good.inventory)? 'danger':'primary'"
                            class="inventoryTag"
                            id="statusPesticide">{{good.inventory}}&nbsp;{{good.middleUnitName}}
                          </el-tag>
                        </div>
                      </div>


                      <div class="bottom clearfix">
                        <el-button
                          v-if="!good.added && good.inventory>0 && good.suggestUsageCount<=good.inventory && good.status===0"
                          class="addCart" type="danger"
                          icon="el-icon-shopping-bag-2"
                          @click="addGood(good)">
                          添加购物车
                        </el-button>
                        <el-radio-group
                          v-if="!good.added && good.inventory>0 && good.status===0 && good.suggestUsageCount>good.inventory"
                          class="selectCart" v-model="selectCart" @change="selectCartChange(selectCart,good)">
                          <el-radio-button @click.native.prevent="addGood(good)" :label="1"><i
                            class="el-icon-shopping-bag-2"></i> 添加购物车
                          </el-radio-button>
                          <el-radio-button @click.native.prevent="toStockOrder(good)" :label="2">进货</el-radio-button>
                        </el-radio-group>
                        <el-button v-if="good.added && good.inventory>0" disabled class="addCart" type="danger"
                                   icon="el-icon-s-goods">
                          商品已添加
                        </el-button>
                        <el-button v-if="good.status===0 && good.inventory<1" class="addCart" type="warning"
                                   icon="el-icon-sell"
                                   @click="toStockOrder(good)">
                          去进货
                        </el-button>
                        <el-button v-if="good.status===1 && good.inventory<1" class="addCart" type="warning"
                                   icon="el-icon-sell"
                                   @click="toOpenStockGood(good)">
                          上架并进货
                        </el-button>
                        <el-button v-if="good.status===1 && good.inventory>0" class="addCart" type="warning"
                                   icon="el-icon-sell"
                                   @click="toOpenGood(good)">
                          去上架
                        </el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>
                <div class="noGoodsBox" v-show="noGoods.length>0">
                  <el-tag type="warning">店铺暂无商品：{{noGoods.toString()}}</el-tag>
                </div>
                <div class="selectRecipeBox">
                  <div class="selectRecipeBtn" @click="returnRecipeList">
                    <i class="selectGoodsIcon glyphicon glyphicon-list-alt"></i>
                    <span class="selectGoodsTitle">选择处方</span>
                  </div>
                </div>
                <div class="selectGoodsBox">
                  <div class="selectGoodsBtn" @click="recipeSelectGoods">
                    <i class="selectGoodsIcon el-icon-circle-plus"></i>
                    <span class="selectGoodsTitle">选择添加</span>
                  </div>
                </div>
              </div>
            </div>
            <!--处方列表-->
            <div v-show="!goodsCart.recipeId && (!isHLJ || (isHLJ && isDN))">
              <el-row>
                <div class="topNavRight">
                  <el-select v-model="recipeCatInput" placeholder="请选择" class="cftype" @change="recipeCategoryChange">
                    <el-option
                      v-for="item in cftypeoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-cascader class="categoryInput inputOptionLine" ref="recipeCategoryInput"
                               placeholder="选择作物"
                               v-model="cropCategory"
                               :options="cropCategorys"
                               :props="{value:'categoryId',label:'categoryName',children:'childrenNode'}"
                               separator=" / "
                               @change="cropCategoryChange">
                  </el-cascader>
                  <el-input class="searchInput"
                            placeholder="处方名称、防治对象、商品名称"
                            v-model="recipeListInput"
                            :blur="searchInputChange(recipeListInput)"
                            :clearable="true">
                  </el-input>
                  <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
                </div>
                <div class="navRight">
                  <el-button class="searchBtn" type="danger"
                             v-if="(getAuthorityMenu('500401') || getAuthorityMenu('300105')) && (!isHLJ || (isHLJ && isDN))"
                             @click="addRecipel">新增处方
                  </el-button>
                  <el-button class="refreshBtn" icon="el-icon-refresh" circle @click="getSearch(1)"></el-button>
                </div>
              </el-row>

              <div class="mainRecipeForm">
                <el-table
                  ref="multipleTable"
                  tooltip-effect="dark"
                  :data="recipeList"
                  height="680px"
                  border
                  highlight-current-row
                  class="previous-row"
                  @current-change="handleCurrentChange"

                  :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
                >
                  <el-table-column type="index" label="序号" class="indexCenter" width="80"></el-table-column>
                  <el-table-column property="name" label="处方名称" width="200"></el-table-column>
                  <el-table-column property="type" label="处方类型" width="120">
                    <template slot-scope="scope">
                      <el-tag
                        :type="scope.row.type === 0 ? 'primary':scope.row.type === 1 ?'danger':scope.row.type === 2 ?'success':'info'"
                        class="showCenter"
                        id="recipeType">{{RECIPE_CAT | optionObjectKV(scope.row.type)}}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column property="cropName" label="使用作物" width="310"
                                   :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column property="happenLevel" label="发生程度" width="160">
                    <template slot-scope="scope">
                      <el-tag
                        :type="scope.row.happenLevel === 0 ? 'primary':scope.row.happenLevel > 0 && scope.row.happenLevel < 4?'warning':scope.row.happenLevel >=4 ?'danger':'info'"
                        class="showCenter"
                        id="happenLevel">{{HAPPEN_LEVEL | optionObjectKV(scope.row.happenLevel)}}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                    class="optionList"

                    label="选择"
                    width="130">
                    <!--使用slot-scope传递当前行参数-->
                    <template slot-scope="scope">
                      <el-button
                        class="recipeCheckMenu"
                        type="primary"
                        @click="checkRecipe(scope.row)">
                        <!--<el-image class="recipeCheckIcon" :src="imgUrls.recipe_rec"></el-image>-->选择处方
                        <i class="glyphicon glyphicon-list-alt "></i>
                      </el-button>

                    </template>

                  </el-table-column>
                </el-table>
              </div>
            </div>

          </div>
          <page-helper v-show="showPage" :class="(goodsCart.recipeId && recipeAddGoods)?'topPage':''" class="pageCommon"
                       ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch" @rePage="rePage"></page-helper>
        </div>

      </el-col>
    </el-row>

    <div class="dialogs">
      <dialog-client-choose ref="dialogClientChoose" @getClients="getClients"
                            :can-check-pro="canCheckPro"></dialog-client-choose>
      <dialog-sale-order ref="dialogSaleOrder" :isHLJ="isHLJ" :isDN="isDN" :hljMode="hljMode" @addSaleCart="addSaleCart"
                         @editSaleCart="editSaleCart" :usage-list="usageList"
                         @cartChange="cartChange" @toStockOrder="toStockOrder"></dialog-sale-order>
      <dialog-sale-order-sure ref="dialogSaleOrderSure" @saveFinish="saveFinish"></dialog-sale-order-sure>
      <dialog-stock-order ref="dialogStockOrder" @saveStockCart="saveStockCart" @saveStockSaleCart="saveStockSaleCart"
                          @cartChange="cartChange"></dialog-stock-order>
    </div>
  </div>
</template>

<script>
  import DialogClientChoose from '../../../components/DialogClientChoose'
  import PageHelper from '../../../components/PageHelper'
  import DialogSaleOrder from '../../../components/DialogSaleOrder'
  import DialogSaleOrderSure from '../../../components/DialogSaleOrderSure'
  import DialogStockOrder from '../../../components/DialogStockOrder'

  export default {
    name: 'SaleOrder',
    components: {
      DialogClientChoose,
      DialogSaleOrder,
      DialogSaleOrderSure,
      DialogStockOrder,
      PageHelper
    },
    data () {
      return {
        message: '销货开单页面',
        //type=1,进货，=2新增商品
        type: 0,
        //供应商
        clientId: '',
        inputVisible: false,
        //弹框
        centerDialogVisible: false,
        sureDialogVisible: false,
        //购物车
        goodCart: {},
        goodsCart: {
          accountsPayable: 0,
          discountMoney: 0,
          paymentAmount: 0,
          creditMoney: 0,
          num: 0,
          details: [],
          clientId: [],
          clientName: [],
        },
        greenWidth:144,
        good: {},
        goods: [],
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        showClearBtn: false,
        base_url: '',
        //分类
        //级联选择
        categorys: [
          {
            'categoryId': 0,
            'categoryName': '全部',
            'categotyOrder': 0,
            'children': [
              {
                'categoryId': 0,
                'categoryName': '全部分类',
                'categotyOrder': 0,
                'children': [],
                'parentCategoryId': 0
              }
            ],
            'parentCategoryId': 0
          }
        ],
        category: {},
        categoryId: '',
        //搜索
        searchInput: '',
        //扫码传参
        qrcode: '',
        timer: -1,
        //分页切换
        currentPage: 1,
        //库存不足
        stockFlag: false,
        remarkFlag: false,
        isPesticide: 2, // 2：非农药  1:农药
        saleMode: false, //false非农药 true农药
        imgUrls: {
          recipe_rec: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/recipe_white.png',
          recipe_sale: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/recipe_sale.png',
          recipe_r: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/sale-recipe-1.png',
          recipe_s: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/sale-sale-1.png',
          phone: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/phone.png',
        },
        //处方
        recipeListInput: '',
        recipeCategoryId: '',
        //处方类型查询下拉框
        cftypeoptions: [
          {
            value: '',
            label: '全部'
          }, {
            value: '0',
            label: '常规处方'
          }, {
            value: '1',
            label: '专家处方'
          }, {
            value: '2',
            label: '门店处方'
          },
        ],
        cropCategorys: [
          {
            'categoryId': 0,
            'categoryName': '全部',
            'categotyOrder': 0,
            'children': [
              {
                'categoryId': 0,
                'categoryName': '全部分类',
                'categotyOrder': 0,
                'children': [],
                'parentCategoryId': 0
              }
            ],
            'parentCategoryId': 0
          }
        ],
        cropCategory: {},
        recipeList: [],//处方列表
        currentRecipe: {},//当前处方

        recipeGoodsList: [],//处方商品列表
        recipeDealGoods: [],//处方处理商品列表
        recipeDealGoodsTemp: [],//接收缓存数据
        recipeDealGoodsDel: [],//删除的商品列表
        recipeDealCart: {},//处方商品购物车
        recipeCatInput: '',//处方分类绑定
        RECIPE_CAT: RECIPE_CAT,
        HAPPEN_LEVEL: HAPPEN_LEVEL,
        GOODS_STATUS: GOODS_STATUS,
        currentRow: null,
        recipeAddGoods: false,//是否选择添加商品，true为选择添加
        noGoods: [],//处方商品店铺不存在
        showPage: true,
        selectCart: {
          type: Number,
          default: 0
        },
        canCheckPro: false,
        usageList: [],//用药list
        realPaymentMax: 0,
        isHLJ: LocalStorage.get(IS_HLJ),
        isDN: LocalStorage.get(IS_DN),
        hljMode: 1,//1农药2其他商品
        colSpanLeft: 9,
        colSpanRight: 15,
        codeUrlList: [],//扫码后的数组
        showRange: false,//左侧购物车是否显示施用范围
        rangeFlag: true,
      }
    },
    mounted () {
      // console.log("currentRecipe--------------------------: ",this.currentRecipe)
      // console.log("SessionStorage.get(SALE_RECIPE_CURRENT)",SessionStorage.get(SALE_RECIPE_CURRENT))

      if (Tool.isNotEmpty(SessionStorage.get(HLJ_MODE))) {
        this.hljMode = SessionStorage.get(HLJ_MODE)
      }
      if (this.isHLJ && this.hljMode === 1 && !this.isDN) {
        this.colSpanLeft = 24
        this.colSpanRight = 0
      }
      if (this.isHLJ && this.hljMode === 2) {
        this.colSpanLeft = 9
        this.colSpanRight = 15
      }

      //console.log("this.codeUrlList: ",this.codeUrlList)
      document.getElementById('scanCode').focus()
      let flag = Tool.getAuthorityRouter(this.$route.path.substr(1))
      if (!flag) {
        this.$emit('noAuth')
      }
      this.getUsageList()
      //应付金额
      this.goodsCart.accountsPayable = 0
      //优惠金额
      this.goodsCart.discountMoney = 0
      //实付金额
      this.goodsCart.paymentAmount = 0
      //赊账
      this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
      // console.log("SessionStorage.get(SALE_CART): ",SessionStorage.get(SALE_CART))

      // console.log("SessionStorage.get(SALE_RECIPE_DEAL_CART):",SessionStorage.get(SALE_RECIPE_DEAL_CART))

      // if (this.type === 0 && Tool.isNotEmpty(SessionStorage.get(SALE_CART))) {
      //   console.log("aaaaaaaaaaaaaaaa",this.isPesticide)
      //   this.realPaymentMax = SessionStorage.get(SALE_CART).paymentAmount
      //   this.goodsCart = SessionStorage.get(SALE_CART)
      //   this.goodsCart.paymentAmount = SessionStorage.get(SALE_CART).accountsPayable ? SessionStorage.get(SALE_CART).accountsPayable : 0.00
      // }

      if (Tool.isNotEmpty(SessionStorage.get(SALE_RECIPE_TYPE))) {
        // console.log("????")
        this.isPesticide = SessionStorage.get(SALE_RECIPE_TYPE)
        if (this.isPesticide === 2 || this.isHLJ) {
          // console.log("----++",SessionStorage.get(SALE_CART))
          if (Tool.isNotEmpty(SessionStorage.get(SALE_CART))) {
            this.realPaymentMax = SessionStorage.get(SALE_CART).paymentAmount
            this.goodsCart = SessionStorage.get(SALE_CART)
            this.goodsCart.paymentAmount = SessionStorage.get(SALE_CART).accountsPayable ? SessionStorage.get(SALE_CART).accountsPayable : 0.00
          }
        } else if (this.isPesticide === 1 || this.isPesticide === 3 || this.isPesticide === 4) {
          // console.log("--------------------")
          if (!Tool.isEmpty(SessionStorage.get(SALE_RECIPE_DEAL_CART))) {
            this.goodsCart = SessionStorage.get(SALE_RECIPE_DEAL_CART)
            //console.log("this.goodsCart: ",this.goodsCart)
            this.realPaymentMax = SessionStorage.get(SALE_RECIPE_DEAL_CART).paymentAmount
          }

        }
        this.clientIds = this.goodsCart.clientId
        this.clientNames = this.goodsCart.clientName ? this.goodsCart.clientName : ''
        this.getClients(this.clientIds, this.clientNames, this.isPesticide, this.hljMode)
      }
      //saleMode=true： 销货开单isPesticide=2  saleMode=false： 处方开单isPesticide=1
      //this.saleMode=(this.isPesticide===2?true:false)

      //orderType=0： 销货开单isPesticide=2  orderType=1： 处方开单isPesticide=1
      //this.isPesticide=this.goodsCart.orderType===0?2:1
      // console.log("this.$route.params: ",this.$route.params)
      // console.log("this.goodsCart: ",this.goodsCart)
      if (Tool.isNotEmpty(this.$route.params)) {
        this.type = this.$route.params.type
        if (this.type === 4) {
          let _that = this
          this.navChange()
          let detailsTemp = []
          let noInventoryTemp = []
          for (let i = 0; i < this.$route.params.item.details.length; i++) {
            detailsTemp.push(this.$route.params.item.details[i])
            if (this.$route.params.item.details[i].inventory < 1) {
              noInventoryTemp.push(this.$route.params.item.details[i].goodsId)
            }
          }
          if (noInventoryTemp.length > 0) {
            for (let i = 0; i < detailsTemp.length; i++) {
              if (detailsTemp[i].goodsId === noInventoryTemp[i]) {
                detailsTemp.splice(i)
              }
            }
          }
          this.goodsCart = this.$route.params.item
          this.goodsCart.details = detailsTemp

          this.goodsCart = $.extend({}, this.goodsCart)
          this.clientIds = this.goodsCart.clientId
          this.clientNames = this.goodsCart.clientName
          this.goodsCart.num = this.goodsCart.details.length
          for (let i = 0; i < this.goodsCart.details.length; i++) {
            if (this.goodsCart.details[i].buyPackageLevel === 2) {
              if (this.goodsCart.details[i].inventory < this.goodsCart.details[i].middleCount * this.goodsCart.details[i].goodsNumber) {
                this.goodsCart.details[i].buyPackageLevel = 1
                this.goodsCart.details[i].goodsNumber = 1
                this.goodsCart.details[i].goodsPrice = this.goodsCart.details[i].lastSmallPrice
                this.goodsCart.details[i].totalPrice = this.goodsCart.details[i].goodsNumber * this.goodsCart.details[i].goodsPrice
              }
            } else if (this.goodsCart.details[i].buyPackageLevel === 1) {
              if (this.goodsCart.details[i].inventory < this.goodsCart.details[i].goodsNumber) {
                this.goodsCart.details[i].goodsNumber = 1
                this.goodsCart.details[i].goodsPrice = this.goodsCart.details[i].lastSmallPrice
                this.goodsCart.details[i].totalPrice = this.goodsCart.details[i].goodsNumber * this.goodsCart.details[i].goodsPrice
              }
            }
          }
          if (this.isHLJ) {
            this.hljMode = 2
            this.colSpanLeft = 9
            this.colSpanRight = 15
            this.saleMode = true
            this.showPage = true
          }
          this.updateCat()
        }
        if (this.type === 5) {
          if (this.$route.params.item) {
            let newGood = this.$route.params.item
            //console.log("newGood: ",newGood)
            if (newGood.inventory < 1) {
              newGood.added = false
              this.$toast.error('新商品库存不足：' + newGood.goodsName + ' ，剩余库存：' + newGood.inventory + newGood.middleUnitName
                + ' 需进货！', 'center')
            } else {
              if (newGood.buyPackageLevel === 2) {
                if (newGood.inventory < newGood.middleCount * newGood.goodsNumber) {
                  newGood.buyPackageLevel = 1
                }
              }
              if (!newGood.lastSmallPrice){
                newGood.lastSmallPrice=0
              }
              if (!newGood.lastBigPrice){
                newGood.lastBigPrice=0
              }
              let canPush = true
              this.goodsCart.details.map(item => {
                if (item.goodsId == this.$route.params.item.goodsId) {
                  canPush = false
                }
              })
              if (canPush) {
                this.goodsCart.details.push(newGood)
              }

              this.goodsCart = $.extend({}, this.goodsCart)
              this.updateCat()
            }
          }
          if (this.$route.params.client) {
            this.goodsCart.clientId = []
            this.goodsCart.clientId.push(this.$route.params.client.clientId)
            this.goodsCart.clientName = []

            if (!this.$route.params.client.idCertified) {
              this.canCheckPro = true
            }
            let tempName = this.$route.params.client.clientName
            if (Tool.isEmpty(this.$route.params.client.clientName)) {
              tempName = []
              tempName.push(this.$route.params.client.clientPerson)
            }
            this.goodsCart.clientName.push(tempName)
            this.clientNames = this.goodsCart.clientName
            this.clientIds = this.goodsCart.clientId

            this.goodsCart = $.extend({}, this.goodsCart)
            this.getClients(this.goodsCart.clientId, this.goodsCart.clientName, this.isPesticide)
            this.updateCat()
            this.showChooseDialog()
          }
        }
        if (this.type === 6) {//实名认证传入
          if (this.$route.params.client) {
            this.showChooseDialog()
          }
        }
        if (this.type === 7) {
          console.log('新增处方完成')
        }
      }
      if (this.goodsCart.clientName === undefined || this.goodsCart.clientName.length < 1) {
        this.showChooseDialog()
      } else {

        //this.getSearch(1)
      }

      this.changeWidth()
      //this.getSearch(1);
      this.getCategorys()
      if (Tool.isNotEmpty(this.clientIds)) {
        this.getSearch(1)
      }
      this.navChange()
      this.base_url = this.BASE_URL
      //this.base_url = 'https://jxccs.weituodata.com:81'
      if (this.type != 5) {
        setTimeout(function () {
          if (document.getElementsByClassName('swal2-container')[0]) {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.display = 'none'
          }
        }, 50)
      }

      //更新是否显示施用范围
      if (this.goodsCart.details.length > 0) {
        if (this.goodsCart.details.some(item => (item.categoryId.toString()[0] == 2 || item.categoryId.toString()[0] == 7))) {
          this.showRange = true
        } else {
          this.showRange = false
        }
      } else {
        this.showRange = false
      }

    },
    methods: {

      handleClick (event) {
        document.getElementById('scanCode').focus()
      },
      handleNoClick (event) {
        document.getElementById('scanCode').blur()
      },
      remarkBlur (e) {
        // console.log("e: ",e,this.goodsCart.remark)
        this.$forceUpdate()
      },
      qrCodeInput (e) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.qrcode.indexOf('&') !== -1) {
            this.qrcode = this.qrcode.replace(new RegExp('&', 'g'), '%26')
          }
          if (this.isPesticide === 1 || this.isPesticide === 3) {
            if (this.isPesticide === 1 && (!this.isHLJ || (this.isHLJ && this.isDN))) {
              this.$toast.error('请先选择农药处方，才可以扫码添加商品！')
            } else if (this.isPesticide === 3 && (!this.isHLJ || (this.isHLJ && this.isDN))) {
              this.$toast.error('请点击\'选择添加\'按钮，到达处方商品添加页面，进行扫码添加商品！')
            }
          } else {
            Loadings.show()
            this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/decodeQrCode?qrcode=' + this.qrcode)
              .then((response) => {
                Loadings.hide()
                let resp = response.data.data
                let barCodeGood = resp
                // console.log("barCodeGoodL: " ,barCodeGood)

                barCodeGood.barcode = this.qrcode
                this.getCodeGood(barCodeGood)
              })
          }
        }, 50)

      },
      getCodeGood (barCodeGood) {
        let _this = this
        let current = 1
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let size = 10
        let showStop = 1
        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        let reqUrl = process.env.VUE_APP_SERVER + '/psi/saleorder/selectByScan?qrCode='
          + barCodeGood.barcode + '&isPesticide=' + (_this.isPesticide !== 2 ? 1 : '') + '&clientId=' + this.goodsCart.clientId
        if (this.isHLJ && !this.isDN) {
          reqUrl = process.env.VUE_APP_SERVER + '/psi/saleorder/queryByScan?qrCode='
            + barCodeGood.barcode + '&isPesticide=' + this.hljMode + '&clientId=' + this.goodsCart.clientId
        }
        Loadings.show()
        this.$axios.get(reqUrl)
          .then((response) => {
            //console.log("扫码响应：",response)
            Loadings.hide()
            let resp = response.data
            let _this = this
            if (resp.code === 200) {
              if (resp.data.records.length < 1) {
                if (this.getAuthorityMenu('300102') || this.getAuthorityMenu('500101')) {
                  if (!this.isHLJ || (this.isHLJ && this.isDN)) {
                    this.$toast.showCodeConfirm('暂无此商品', barCodeGood.goodsName ? barCodeGood.goodsName : '', '是否去新增', '去新增', function () {
                        let type = 5
                        _this.$router.push({
                          name: '/goodsManagerEdit',
                          params: {
                            type,
                            item: barCodeGood,
                            isPesticide: _this.isPesticide === 2 ? 2 : 1
                            /*supplierNames: _this.goodsCart.clientName,
                            supplierIds: _this.goodsCart.clientId,*/
                          }
                        })
                      }
                    )
                  } else {
                    this.$toast.error('暂无此商品', 'center')
                  }

                } else {
                  this.$toast.showAuth('当前登录用户没有访问权限，请重新登录！', 'center')
                }

              } else if (resp.data.records[0].status === 0 && resp.data.records[0].inventory > 0) {
                if (!Tool.isEmpty(barCodeGood.barcode) && barCodeGood.pdno) {
                  resp.data.records[0].codeUrl = barCodeGood.barcode
                }
                if (this.isHLJ && this.hljMode === 1 && !this.isDN) {
                  let codeUrls = []
                  if (this.codeUrlList.length > 0) {
                    for (let i = 0; i < this.codeUrlList.length; i++) {
                      codeUrls.push(this.codeUrlList[i].codeUrl)
                    }
                  }

                  let goodsIds = []
                  for (let i = 0; i < this.goodsCart.details.length; i++) {
                    goodsIds.push(this.goodsCart.details[i].goodsId)
                  }
                  let goodItem = {}
                  if (this.goodsCart.details.length > 0) {
                    for (let i = 0; i < this.goodsCart.details.length; i++) {
                      if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                        goodItem = resp.data.records[0]
                      }
                    }
                    if (Tool.isEmpty(goodItem)) {
                      goodItem = resp.data.records[0]
                    }
                    if (codeUrls.indexOf(barCodeGood.barcode) === -1 && goodsIds.indexOf(goodItem.goodsId) !== -1) {
                      for (let i = 0; i < this.goodsCart.details.length; i++) {
                        if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId && resp.data.records[0].inventory >= this.goodsCart.details[i].goodsNumber + 1) {
                          this.goodsCart.details[i].goodsNumber += 1
                          this.goodsCart.details[i].totalPrice = this.goodsCart.details[i].goodsNumber * this.goodsCart.details[i].goodsPrice
                          if (barCodeGood.pdno) {
                            this.codeUrlList.push({
                              goodsId: this.goodsCart.details[i].goodsId,
                              codeUrl: barCodeGood.barcode
                            })
                            SessionStorage.set(CODE_URL_LIST, this.codeUrlList)
                          }

                          break
                        }
                        if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId && resp.data.records[0].inventory < this.goodsCart.details[i].goodsNumber + 1) {
                          let relevanceGood = resp.data.records[0]
                          if (barCodeGood.pdno) {
                            relevanceGood.codeUrl = barCodeGood.barcode
                          }

                          this.toStockOrder(relevanceGood)
                          break
                        }
                      }
                      this.updateCat()
                      this.$message({
                        type: 'success',
                        message: '商品添加成功！',
                        duration: 2000,
                        offset: 0
                      })
                    } else if (codeUrls.indexOf(barCodeGood.barcode) === -1 && goodsIds.indexOf(goodItem.goodsId) === -1) {
                      //console.log("商品不存在进行添加：",resp.data.records[0])
                      resp.data.records[0].buyPackageLevel = 1
                      if (!resp.data.records[0].goodsPrice) {
                        resp.data.records[0].goodsPrice = resp.data.records[0].lastSmallPrice
                      }
                      goodItem = resp.data.records[0]
                      this.addGood(goodItem)
                    } else {
                      //console.log("barCodeGood.barcode: ",barCodeGood.barcode,"goodsItem: ",goodItem)
                      this.$message({
                        type: 'error',
                        message: '商品已添加购物车！',
                        duration: 2000,
                        offset: 110
                      })
                    }
                    this.updateCat()
                  } else {
                    let goodsCartFlag = false
                    for (let i = 0; i < this.goodsCart.details.length; i++) {
                      if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                        goodsCartFlag = true
                      }
                    }
                    if (!goodsCartFlag) {
                      let good = resp.data.records[0]
                      if (barCodeGood.pdno) {
                        good.codeUrl = barCodeGood.barcode
                      }

                      good.buyPackageLevel = good.lastPackageLevel
                      if (good.lastPackageLevel === 1) {
                        good.goodsPrice = good.lastSmallPrice
                      }
                      if (good.lastPackageLevel === 2) {
                        good.goodsPrice = good.lastBigPrice
                        if (good.inventory < good.middleCount) {
                          good.buyPackageLevel = 1
                        }
                      }
                      good.saleBigPrice = good.lastBigPrice
                      good.saleSmallPrice = good.lastSmallPrice
                      this.addGood(good)
                    }
                  }
                } else {
                  let goodsCartFlag = false
                  for (let i = 0; i < this.goodsCart.details.length; i++) {
                    if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                      goodsCartFlag = true
                    }
                  }
                  if (!goodsCartFlag) {
                    let good = resp.data.records[0]
                    if (barCodeGood.pdno) {
                      good.codeUrl = barCodeGood.barcode
                    }
                    // good.codeUrl=barCodeGood.barcode
                    good.buyPackageLevel = good.lastPackageLevel
                    if (good.lastPackageLevel === 1) {
                      good.goodsPrice = good.lastSmallPrice
                    }
                    if (good.lastPackageLevel === 2) {
                      good.goodsPrice = good.lastBigPrice
                      if (good.inventory < good.middleCount) {
                        good.buyPackageLevel = 1
                      }
                    }
                    good.saleBigPrice = good.lastBigPrice
                    good.saleSmallPrice = good.lastSmallPrice
                    this.addGood(good)
                  }
                }

              } else if (resp.data.records[0].status === 0 && resp.data.records[0].inventory < 1) {
                let relevanceGood = resp.data.records[0]
                if (barCodeGood.pdno) {
                  relevanceGood.codeUrl = barCodeGood.barcode
                }

                this.toStockOrder(relevanceGood)
              } else if (resp.data.records[0].status === 1 && resp.data.records[0].inventory > 0) {
                if (this.getAuthorityMenu('500103')) {
                  this.$toast.showCodeConfirm('该商品已下架：', barCodeGood.goodsName, '是否去上架', '去上架', function () {
                      let relevanceGood = resp.data.records[0]
                      if (barCodeGood.pdno) {
                        relevanceGood.codeUrl = barCodeGood.barcode
                      }

                      _this.goOpenStock(relevanceGood)
                    }
                  )
                } else {
                  this.$toast.showAuth('当前登录用户没有访问权限，请重新登录！', 'center')
                }

              } else if (resp.data.records[0].status === 1 && resp.data.records[0].inventory < 1) {
                if (this.getAuthorityMenu('500103')) {
                  this.$toast.showCodeConfirm('该商品已下架且无库存：', barCodeGood.goodsName, '是否去上架并进货', '去上架并进货', function () {
                      let relevanceGood = resp.data.records[0]
                      let inventoryFlag = true
                      _this.goOpenStock(relevanceGood, inventoryFlag)
                    }
                  )
                } else {
                  this.$toast.showAuth('当前登录用户没有访问权限，请重新登录！', 'center')
                }

              }
              this.qrcode = ''
            } else {
              this.$toast.error(resp.msg, 'center')
            }

          })
      },
      //上架
      goOpenStock (goods, inventoryFlag, suggestUsageCount) {
        Loadings.show()
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/upShelves?goodsIds=' + goods.goodsId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data.data
            resp.goodsNumber = 1
            resp.buyPackageLevel = 1
            resp.goodsPrice = resp.saleSmallPrice
            if (goods.codeUrl) {
              resp.codeUrl = goods.codeUrl
            }
            if (this.isPesticide !== 2) {
              resp.usageAmount = 1
              resp.usageId = 1
              resp.usageName = '喷雾法'
            }
            resp.totalPrice = resp.goodsPrice * resp.goodsNumber
            resp.packageUnit = resp.packingSpecification
            if (goods.codeUrl) {
              resp.codeUrl = goods.codeUrl
            }

            if (inventoryFlag) {
              //上架后，库存不足
              this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/goBuyOrder?goodsId=' + resp.goodsId)
                .then((response) => {
                  Loadings.hide()
                  let resp = response.data
                  this.good = resp.data
                  if (goods.codeUrl) {
                    this.good.codeUrl = goods.codeUrl
                  }

                  this.good.packingSpecification = this.good.packageUnit
                  if (suggestUsageCount) {
                    this.good.suggestUsageCount = suggestUsageCount
                  }
                  if (this.good.suggestUsageCount) {
                    this.good.goodsNumber = this.good.suggestUsageCount
                  } else {
                    this.good.goodsNumber = 1
                  }

                  if (this.good.goodsNumber > 1) {
                    this.good.buyPackageLevel = 1
                    this.good.goodsPrice = this.good.lastSmallPrice * this.good.goodsNumber
                  } else {
                    this.good.buyPackageLevel = 2
                    if (this.good.packageLevel === 1) {
                      this.good.goodsPrice = this.good.lastSmallPrice
                    }
                    if (this.good.packageLevel === 2) {
                      this.good.goodsPrice = this.good.lastBigPrice
                    }
                  }
                  this.good.smallGoodsPrice = this.good.lastSmallPrice
                  this.good.bigGoodsPrice = this.good.lastBigPrice
                  this.good.totalPrice = this.good.goodsNumber * this.good.goodsPrice
                  let supplierName = ''
                  if (this.good.supplierList.length > 0) {
                    supplierName = this.good.supplierList[0].supplierName
                  }
                  //不管是否去进货，这里更新status
                  if (this.isPesticide === 3 || this.isPesticide === 4) {
                    for (let i = 0; i < this.recipeDealGoods.length; i++) {
                      if (this.recipeDealGoods[i].goodsId === this.good.goodsId) {
                        this.recipeDealGoods[i].status = 0
                      }
                    }
                  }
                  if (this.good.supplierList.length < 1) {
                    this.$toast.error('该商品无可用供应商，请去商品管理关联供应商！', 'center')
                  } else {
                    this.$nextTick(() => {
                      this.$refs.dialogStockOrder.openStockGood(this.good, supplierName, false, true)
                    })
                  }
                })

            } else {
              let pushCartFlag = false
              if (this.goodsCart.details.length < 1) {
                if (this.isPesticide === 3 || this.isPesticide === 4) {
                  for (let i = 0; i < this.recipeDealGoods.length; i++) {
                    if (this.recipeDealGoods[i].goodsId === resp.goodsId) {
                      this.recipeDealGoods[i].status = 0
                      this.recipeDealGoods[i].added = true
                    }
                  }
                  if (this.isPesticide === 4) {
                    for (let i = 0; i < this.recipeGoodsList.length; i++) {
                      if (this.recipeGoodsList[i].goodsId === resp.goodsId) {
                        this.recipeGoodsList[i].added = true
                      }
                    }
                  }
                }

                if (this.isHLJ && this.hljMode === 1 && !this.isDN) {
                  this.goodsCart.details.push(resp)

                  if (goods.codeUrl) {
                    this.codeUrlList.push({
                      goodsId: goods.goodsId,
                      codeUrl: goods.codeUrl
                    })
                    SessionStorage.set(CODE_URL_LIST, this.codeUrlList)
                  }
                } else {
                  this.goodsCart.details.push(resp)
                }

                //更新是否显示施用范围
                if (this.goodsCart.details.some(item => (item.categoryId.toString()[0] == 2 || item.categoryId.toString()[0] == 7))) {
                  this.showRange = true
                } else {
                  this.showRange = false
                }

              } else {
                for (let i = 0; i < this.goodsCart.details.length; i++) {
                  this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
                  if (this.goodsCart.details[i].goodsId !== resp.goodsId) {
                    pushCartFlag = true
                  }
                }
              }
              if (pushCartFlag) {
                if (this.isPesticide === 3 || this.isPesticide === 4) {
                  for (let i = 0; i < this.recipeDealGoods.length; i++) {
                    if (this.recipeDealGoods[i].goodsId === resp.goodsId) {
                      this.recipeDealGoods[i].status = 0
                      this.recipeDealGoods[i].added = true
                    }
                  }
                  if (this.isPesticide === 4) {
                    for (let i = 0; i < this.recipeGoodsList.length; i++) {
                      if (this.recipeGoodsList[i].goodsId === resp.goodsId) {
                        this.recipeGoodsList[i].added = true
                      }
                    }
                  }
                }

                if (this.isHLJ && this.hljMode === 1 && !this.isDN) {
                  this.goodsCart.details.push(resp)
                  if (goods.codeUrl) {
                    this.codeUrlList.push({
                      goodsId: goods.goodsId,
                      codeUrl: goods.codeUrl
                    })
                    SessionStorage.set(CODE_URL_LIST, this.codeUrlList)
                  }
                } else {
                  this.goodsCart.details.push(resp)
                }
                //更新是否显示施用范围
                if (this.goodsCart.details.some(item => (item.categoryId.toString()[0] == 2 || item.categoryId.toString()[0] == 7))) {
                  this.showRange = true
                } else {
                  this.showRange = false
                }
              }
              this.updateCat()
            }

          })
      },
      //更换客戶
      modifySupplier (good) {
        this.$axios.post(process.env.VUE_APP_SERVER + '/psi/suppliergoosrel/save', {
          goodsId: good.goodsId,
          supplierId: this.goodsCart.supplierId.toString()
        })
          .then((response) => {
            Loadings.hide()
            let resp = response.data.data
            resp.belongToSupplier = true
            if (resp.buyPackageLevel === 1) {
              resp.goodsPrice = resp.smallGoodsPrice
            } else if (resp.buyPackageLevel === 2) {
              resp.goodsPrice = resp.bigGoodsPrice
            }
            resp.totalPrice = parseFloat(resp.goodsPrice) * resp.goodsNumber
            let pushCartFlag = false
            if (this.goodsCart.details.length < 1) {
              this.goodsCart.details.push(resp)
            } else {
              for (let i = 0; i < this.goodsCart.details.length; i++) {
                this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
                if (this.goodsCart.details[i].goodsId !== resp.goodsId) {
                  pushCartFlag = true
                }
              }
              this.goodsCart.details.push(resp)
            }
            this.updateCat()
          })
      },
      //更新购物车更新缓存
      updateCat () {
        //更新是否显示施用范围
        if (this.goodsCart.details.some(item => (item.categoryId.toString()[0] == 2 || item.categoryId.toString()[0] == 7))) {
          this.showRange = true
        } else {
          this.showRange = false
        }
        this.goodsCart.accountsPayable = 0
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
        }
        this.goodsCart.num = this.goodsCart.details.length
        this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        this.realPaymentMax = this.goodsCart.accountsPayable
        this.goodsCart.discountMoney = 0
        this.goodsCart.creditMoney = parseFloat('0').toFixed(2)
        if (this.isPesticide === 2 || this.isHLJ) {
          SessionStorage.set(SALE_CART, this.goodsCart)
          if (!this.isHLJ || (this.isHLJ && !this.isDN && this.hljMode === 2) || (this.isHLJ && this.isDN)) {
            this.getSearch(1)
          }

        } else {
          SessionStorage.set(SALE_RECIPE_DEAL_GOODS_DEL, this.recipeDealGoodsDel)
          SessionStorage.set(SALE_RECIPE_DEAL_GOODS, this.recipeDealGoods)
          SessionStorage.set(SALE_RECIPE_DEAL_CART, this.goodsCart)
          if (!this.isHLJ || (this.isHLJ && !this.isDN && this.hljMode == 2) || (this.isHLJ && this.isDN) || this.isPesticide === 1 || this.isPesticide === 4) {
            this.getSearch(1)
          }
        }

      },

      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //获取所有分类
      getCategorys () {
        let reqUrl = process.env.VUE_APP_SERVER + '/psi/category/selectCategory?type=' + (this.saleMode === true ? '' : 2)
        if (this.isHLJ && !this.isDN) {
          reqUrl = process.env.VUE_APP_SERVER + '/psi/category/selectCategoryTree?type=' + (this.hljMode === 1 ? 2 : 1)
        }
        /*this.$axios.get(process.env.VUE_APP_SERVER + '/psi/category/selectAll')*/
        this.$axios.get(reqUrl)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            resp.data.map(item => {
              if (item.categoryName !== '其他') {
                item.children.unshift({
                  categoryId: item.categoryId,
                  categoryName: '全部' + item.categoryName
                })
              }
            })
            this.categorys = [
              {
                'categoryId': 0,
                'categoryName': '全部',
                'categotyOrder': 0,
                'children': [
                  {
                    'categoryId': 0,
                    'categoryName': '全部分类',
                    'categotyOrder': 0,
                    'children': [],
                    'parentCategoryId': 0
                  }
                ],
                'parentCategoryId': 0
              }
            ]
            for (let i = 0; i < resp.data.length; i++) {
              this.categorys.push(resp.data[i])
            }
            for (let i = 0; i < this.categorys.length; i++) {
              for (let j = 0; j < this.categorys[i].children.length; j++) {
                if (this.categorys[i].children[j].children) {
                  delete this.categorys[i].children[j].children
                }
              }
            }
          })
      },
      //选中分类搜索
      categoryChange () {
        let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0]
        this.categoryId = checkedNodes.value
        this.getSearch(1)
      },

      /********处方********/
      recipeCategoryChange () {
        this.getSearch(1)
      },
      goodCategoryChange () {
        let checkedNodes = this.$refs.recipeGoodsCategoryInput.getCheckedNodes()[0]
        this.categoryId = checkedNodes.value
        this.getSearch(1)
      },
      //获取作物分类
      getCropCategorys () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/cropCategory/queryCropCategoryTree')
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.cropCategorys = [
              {
                'categoryId': 0,
                'categoryName': '全部',
                'categoryOrder': 0,
                'childrenNode': [
                  {
                    'categoryId': '',
                    'categoryName': '全部分类',
                    'categoryOrder': 0,
                    'childrenNode': [],
                    'parentCategoryId': 0
                  }
                ],
                'parentCategoryId': 0
              }
            ]
            for (let i = 0; i < resp.data.length; i++) {
              this.cropCategorys.push(resp.data[i])
            }
            for (let i = 0; i < this.cropCategorys.length; i++) {
              for (let j = 0; j < this.cropCategorys[i].childrenNode.length; j++) {
                if (this.cropCategorys[i].childrenNode[j].childrenNode.length < 1) {
                  delete this.cropCategorys[i].childrenNode[j].childrenNode
                }
              }
            }
          })
      },
      //处方分类监听
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //处方分类选择
      cropCategoryChange () {
        let checkedNodes = this.$refs.recipeCategoryInput.getCheckedNodes()[0]
        this.recipeCategoryId = checkedNodes.value
        this.getSearch(1)
      },
      //新增处方
      addRecipel () {
        this.$router.push({
          name: '/recipelManagerEdit',
          params: {
            isPesticide: this.isPesticide
          }
        })
      },
      //处方选择商品
      recipeSelectGoods () {
        this.recipeAddGoods = true
        this.showPage = true
        this.isPesticide = 4
        SessionStorage.set(SALE_RECIPE_TYPE, 4)
        this.getSearch(1)
      },
      //删除处方商品
      delGoods (good) {
        for (let i = 0; i < this.recipeDealGoods.length; i++) {
          if (this.recipeDealGoods[i].goodsId === good.goodsId) {
            this.recipeDealGoods.splice(i, 1)
          }
        }
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          if (this.goodsCart.details[i].goodsId === good.goodsId) {
            this.goodsCart.details.splice(i, 1)
          }
        }
        this.goodsCart.num = this.goodsCart.details.length
        if (this.recipeDealGoodsDel.indexOf(good.goodsId) === -1) {
          this.recipeDealGoodsDel.push(good.goodsId)
        }
        if (this.codeUrlList.length > 0) {
          for (let i = 0; i < this.codeUrlList.length; i++) {
            if (this.codeUrlList[i].goodsId === good.goodsId) {
              this.codeUrlList.splice(i)
            }
          }
        }

        //console.log("当前的codeUrlList：",this.codeUrlList)
        SessionStorage.set(CODE_URL_LIST, this.codeUrlList)
        this.updateCat()

      },
      //返回处方商品
      recipeSelectGoodsReturn () {
        // console.log("返回处方商列表")
        this.recipeAddGoods = false
        this.showPage = false
        this.isPesticide = 3
        // console.log("SessionStorage.get(SALE_RECIPE_CURRENT): ",SessionStorage.get(SALE_RECIPE_CURRENT))
        if (SessionStorage.get(SALE_RECIPE_CURRENT)) {
          this.currentRecipe = SessionStorage.get(SALE_RECIPE_CURRENT)
          // console.log("this.currentRecipe---:",this.currentRecipe)
          this.checkRecipe(this.currentRecipe, true)
        }

        this.$forceUpdate()
        // this.getClients(this.clientIds, this.clientNames, this.isPesticide)
      },
      //返回处方列表
      returnRecipeList () {
        this.isPesticide = 1
        this.clientNames = this.goodsCart.clientName
        this.clientIds = this.goodsCart.clientId
        SessionStorage.remove(SALE_RECIPE_DEAL_GOODS_DEL)
        SessionStorage.remove(SALE_RECIPE_DEAL_CART)
        SessionStorage.remove(SALE_RECIPE_DEAL_GOODS)
        SessionStorage.remove(SALE_CART)
        this.recipeDealGoodsTemp = []
        this.recipeDealGoodsDel = []
        this.recipeDealGoods = []
        this.getClients(this.clientIds, this.clientNames, this.isPesticide)
      },
      //选择处方
      checkRecipe (item, unItem) {
        // console.log('选中的处方：', item, 'unItem: ', unItem)
        if (!unItem) {
          // console.log("2222222222222222222")
          let recipeItem = {
            clientId: this.goodsCart.clientId.toString(),
            recipeType: item.type,
            recipeId: item.recipeId
          }
          this.currentRecipe = recipeItem
          SessionStorage.set(SALE_RECIPE_CURRENT, this.currentRecipe)
        }

        // console.log("this.currentRecipe: ",this.currentRecipe)

        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/recipeOrder?clientId=' + this.currentRecipe.clientId
          + '&recipeType=' + this.currentRecipe.recipeType + '&recipeId=' + this.currentRecipe.recipeId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data.data
            //console.log('resp: ', resp)

            this.recipeDealGoods = []
            if (resp.stopGoods.length > 0) {
              for (let i = 0; i < resp.stopGoods.length; i++) {
                this.recipeDealGoods.push(resp.stopGoods[i])
              }
            }
            if (resp.noInventoryGoods.length > 0) {
              for (let i = 0; i < resp.noInventoryGoods.length; i++) {
                this.recipeDealGoods.push(resp.noInventoryGoods[i])
              }
            }
            if (resp.goods.length > 0) {
              for (let i = 0; i < resp.goods.length; i++) {
                this.recipeDealGoods.push(resp.goods[i])
              }
            }
            this.noGoods = []
            if (resp.noGoods.length > 0) {
              for (let i = 0; i < resp.noGoods.length; i++) {
                this.noGoods.push(resp.noGoods[i].goodsName)
              }
            }
            //this.noGoods = ['商品AAAAAA', '商品BBBBBB', '商品CCCCCC']
            for (let i = 0; i < this.recipeDealGoods.length; i++) {
              this.recipeDealGoods[i].buyPackageLevel = this.recipeDealGoods[i].lastPackageLevel
              this.recipeDealGoods[i].goodsPrice = parseFloat(this.recipeDealGoods[i].saleSmallPrice).toFixed(2)
            }

            for (let j = 0; j < this.recipeDealGoodsDel.length; j++) {
              for (let i = 0; i < this.recipeDealGoods.length; i++) {
                if (this.recipeDealGoodsDel[j] === this.recipeDealGoods[i].goodsId) {
                  this.recipeDealGoods.splice(i, 1)
                }
              }
            }

            // console.log("recipeDealGoods",this.recipeDealGoods)
            // console.log("recipeDealGoodsTemp: ",this.recipeDealGoodsTemp)

            for (let j = 0; j < this.recipeDealGoodsDel.length; j++) {
              for (let i = 0; i < this.recipeDealGoodsTemp.length; i++) {
                if (this.recipeDealGoodsDel[j] === this.recipeDealGoodsTemp[i].goodsId) {
                  this.recipeDealGoodsTemp.splice(i, 1)
                }
              }
            }

            let goodsTemp = []
            if (this.recipeDealGoodsTemp.length > 0) {
              for (let i = 0; i < this.recipeDealGoodsTemp.length; i++) {
                let temp = this.recipeDealGoodsTemp[i]
                let goodsId = temp.goodsId
                let isExist = false
                for (let j = 0; j < this.recipeDealGoods.length; j++) {
                  let tem = this.recipeDealGoods[j]
                  let tempId = tem.goodsId
                  if (goodsId === tempId) {
                    isExist = true
                    break
                  }
                }
                if (!isExist) {
                  goodsTemp.push(temp)
                }
              }
              for (let i = 0; i < goodsTemp.length; i++) {
                this.recipeDealGoods.push(goodsTemp[i])
              }

            }
            for (let j = 0; j < this.recipeDealGoodsTemp.length; j++) {
              for (let k = 0; k < this.recipeDealGoods.length; k++) {
                if (this.recipeDealGoodsTemp[j].goodsId === this.recipeDealGoods[k].goodsId
                  && this.recipeDealGoodsTemp[j].status !== this.recipeDealGoods[k].status) {
                  this.recipeDealGoods.splice(k, 1, this.recipeDealGoodsTemp[j])
                }
              }
            }
            for (let i = 0; i < this.goodsCart.details.length; i++) {
              for (let j = 0; j < this.recipeDealGoods.length; j++) {
                if (this.goodsCart.details[i].goodsId === this.recipeDealGoods[j].goodsId) {
                  this.recipeDealGoods[j].added = true
                }
              }
            }
            this.recipeAddGoods = false
            this.showPage = false
            this.goodsCart.recipeId = item.recipeId
            this.goodsCart.recipeName = item.name
            this.goodsCart.name = item.name
            this.goodsCart.recipeType = item.type
            this.goodsCart.type = item.type
            this.goodsCart.recipeCategoryName = item.recipeCategoryName
            this.isPesticide = 3
            this.$forceUpdate()
            SessionStorage.set(SALE_RECIPE_DEAL_GOODS, this.recipeDealGoods)
            SessionStorage.set(SALE_RECIPE_TYPE, this.isPesticide)
            SessionStorage.set(SALE_RECIPE_DEAL_CART, this.goodsCart)
            this.changeWidth()

          })
      },
      //商品处理：上架商品
      toOpenGood (good) {
        let _this = this
        if (_this.getAuthorityMenu('500103')) {
          this.$toast.showCodeConfirm('该商品已下架：', good.goodsName, '是否去上架', '去上架', function () {
              _this.goOpenStock(good.goodsId)
            }
          )
        } else {
          this.$toast.showAuth('当前登录用户没有访问权限，请重新登录！', 'center')
        }
      },
      //商品处理：上架并进货
      toOpenStockGood (good) {
        let _this = this
        let suggestUsageCount = 0
        if (good.suggestUsageCount) {
          suggestUsageCount = good.suggestUsageCount
        }
        if (_this.getAuthorityMenu('500103')) {
          this.$toast.showCodeConfirm('该商品已下架且无库存：', good.goodsName, '是否去上架并进货', '去上架并进货', function () {
              let inventoryFlag = true
              _this.goOpenStock(good.goodsId, inventoryFlag, suggestUsageCount)
            }
          )
        } else {
          this.$toast.showAuth('当前登录用户没有访问权限，请重新登录！', 'center')
        }
      },
      //选择购物车方式
      selectCartChange (e, good) {
        if (e === 1) {
          this.addGood(good)
        } else if (e === 2) {
          this.toStockOrder(good)
        }
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      rePage (e) {
        this.currentPage = e
      },
      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let resize = 12
        let size = resize
        let keywords = this.searchInput
        let clientId = ''
        let showStop = 0
        if (Tool.isNotEmpty(this.clientIds)) {
          clientId = this.clientIds.toString()
        }

        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        if ((this.saleMode && !this.isHLJ) || (this.saleMode && this.isHLJ && this.isDN)) {//+ '&isPesticide=' + this.isPesticide
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectBySale?current='
            + current + '&size=' + size + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&clientId=' + clientId)
            .then((response) => {
              console.log("1")
              Loadings.hide()
              let resp = response.data
              this.goods = resp.data.records
              for (let i = 0; i < this.goods.length; i++) {
                this.goods[i].buyPackageLevel = this.goods[i].lastPackageLevel

                // if(!this.goods[i].lastSmallPrice || this.goods[i].lastSmallPrice=='0' || this.goods[i].lastSmallPrice=='0.00' || this.goods[i].lastSmallPrice==''){
                //   this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
                // }else{
                //   this.goods[i].goodsPrice = parseFloat(this.goods[i].lastSmallPrice).toFixed(2)
                // }
                if(this.goods[i].saleSmallPrice){
                  this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
                }else{
                  this.goods[i].goodsPrice = parseFloat(this.goods[i].lastSmallPrice).toFixed(2)
                }


              }
              for (let i = 0; i < this.goodsCart.details.length; i++) {
                for (let j = 0; j < this.goods.length; j++) {
                  if (this.goodsCart.details[i].goodsId === this.goods[j].goodsId) {
                    this.goods[j].added = true
                  }
                }
              }
              this.$nextTick(() => {
                this.$refs.pageHelper.render(page, resp.data.total, resize)
              })

              this.$forceUpdate()
            })
        } else if (!this.saleMode && !this.recipeAddGoods && (!this.isHLJ || (this.isHLJ && this.isDN))) {
          // console.log("22222222222222",SessionStorage.get(SALE_RECIPE_CURRENT))
          console.log("2")
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/recipe/queryRecipePage?current='
            + current + '&size=' + size + '&cropId=' + this.recipeCategoryId + '&searchText=' + this.recipeListInput + '&type=' + this.recipeCatInput + '&tenantId=' + JSON.parse(localStorage.getItem('tenant_info')).tenantId)
            .then((response) => {
              Loadings.hide()
              let resp = response.data
              this.recipeList = resp.data.records
              this.$nextTick(() => {
                this.$refs.pageHelper.render(page, resp.data.total, resize)
              })

              this.getCropCategorys()
            })
        } else if (!this.saleMode && this.recipeAddGoods && (!this.isHLJ || (this.isHLJ && this.isDN))) {
          // console.log("333333333333")
          console.log("3")
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectBySale?current='
            + current + '&size=' + size + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&clientId=' + clientId + '&isPesticide='
            + ((this.isPesticide === 3 || this.isPesticide === 4) ? 1 : this.isPesticide))
            .then((response) => {
              Loadings.hide()
              let resp = response.data
              this.recipeGoodsList = resp.data.records
              for (let i = 0; i < this.recipeGoodsList.length; i++) {
                this.recipeGoodsList[i].buyPackageLevel = this.recipeGoodsList[i].lastPackageLevel
                // if(!this.goods[i].lastSmallPrice || this.goods[i].lastSmallPrice=='0' || this.goods[i].lastSmallPrice=='0.00' || this.goods[i].lastSmallPrice==''){
                //   this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
                // }else{
                //   this.goods[i].goodsPrice = parseFloat(this.goods[i].lastSmallPrice).toFixed(2)
                // }
                if(this.goods[i].saleSmallPrice){
                  this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
                }else{
                  this.goods[i].goodsPrice = parseFloat(this.goods[i].lastSmallPrice).toFixed(2)
                }
              }
              for (let i = 0; i < this.goodsCart.details.length; i++) {
                for (let j = 0; j < this.recipeGoodsList.length; j++) {
                  if (this.goodsCart.details[i].goodsId === this.recipeGoodsList[j].goodsId) {
                    this.recipeGoodsList[j].added = true
                  }
                }
              }
              this.$nextTick(() => {
                this.$refs.pageHelper.render(page, resp.data.total, resize)
              })
              // console.log("currentType :",this.currentRecipe)
              // console.log("当前缓存数据：",SessionStorage.get(SALE_RECIPE_CURRENT))
              this.$forceUpdate()
            })
        } else if (this.isHLJ && !this.isDN && this.hljMode === 2) {//+ '&isPesticide=' + this.isPesticide
          console.log("4444444444444444")
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/queryBySale?current='
            + current + '&size=' + size + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&clientId=' + clientId, +'&isPesticide=2')
            .then((response) => {
              Loadings.hide()
              let resp = response.data
              this.goods = resp.data.records
              for (let i = 0; i < this.goods.length; i++) {
                this.goods[i].buyPackageLevel = this.goods[i].lastPackageLevel
                // if(!this.goods[i].lastSmallPrice || this.goods[i].lastSmallPrice=='0' || this.goods[i].lastSmallPrice=='0.00' || this.goods[i].lastSmallPrice==''){
                //   this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
                // }else{
                //   this.goods[i].goodsPrice = parseFloat(this.goods[i].lastSmallPrice).toFixed(2)
                // }
                if(this.goods[i].saleSmallPrice){
                  this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
                }else{
                  this.goods[i].goodsPrice = parseFloat(this.goods[i].lastSmallPrice).toFixed(2)
                }
              }
              for (let i = 0; i < this.goodsCart.details.length; i++) {
                for (let j = 0; j < this.goods.length; j++) {
                  if (this.goodsCart.details[i].goodsId === this.goods[j].goodsId) {
                    this.goods[j].added = true
                  }
                }
              }

              this.$nextTick(() => {
                this.$refs.pageHelper.render(page, resp.data.total, resize)
              })

              this.$forceUpdate()
            })
        }

      },
      reSearch (data) {
        this.getSearch(1, data)
      },

      //添加购物车
      addGood (good) {
        let flag = false
        let saleType = false
        good.added = false
        // console.log("good+++: ",good)
        if (good.suggestUsageCount && good.inventory >= good.suggestUsageCount) {
          good.goodsNumber = good.suggestUsageCount
        } else if (good.suggestUsageCount && good.inventory < good.suggestUsageCount && good.inventory > 0) {
          good.goodsNumber = good.inventory
        } else {
          good.suggestUsageCount = 0
          good.goodsNumber = 1
        }

        if (this.isPesticide === 2) {
          if (good.buyPackageLevel === 2) {
            good.goodsPrice = parseFloat(good.lastBigPrice)
            if (good.inventory < good.middleCount) {
              good.buyPackageLevel = 1
            }
          }
          if (good.buyPackageLevel === 1) {
            // if(!good.lastSmallPrice || good.lastSmallPrice=='0' || good.lastSmallPrice=='0.00' || good.lastSmallPrice==''){
            //   good.goodsPrice = parseFloat(good.saleSmallPrice).toFixed(2) || '0.00'
            // }else{
            //   good.goodsPrice = parseFloat(good.lastSmallPrice).toFixed(2) || '0.00'
            // }
            if(good.saleSmallPrice){
              good.goodsPrice = parseFloat(good.saleSmallPrice).toFixed(2)
            }else{
              good.goodsPrice = parseFloat(good.lastSmallPrice).toFixed(2)
            }
          }
        } else if (this.isPesticide === 3) {
          good.buyPackageLevel = 1
          // if(!good.lastSmallPrice || good.lastSmallPrice=='0' || good.lastSmallPrice=='0.00' || good.lastSmallPrice==''){
          //   good.goodsPrice = parseFloat(good.saleSmallPrice).toFixed(2) || '0.00'
          // }else{
          //   good.goodsPrice = parseFloat(good.lastSmallPrice).toFixed(2) || '0.00'
          // }
          if(good.saleSmallPrice){
            good.goodsPrice = parseFloat(good.saleSmallPrice).toFixed(2)
          }else{
            good.goodsPrice = parseFloat(good.lastSmallPrice).toFixed(2)
          }
        } else {
          // console.log('good-==========', good)
          good.buyPackageLevel = 1

          // if(!good.lastSmallPrice || good.lastSmallPrice=='0' || good.lastSmallPrice=='0.00' || good.lastSmallPrice==''){
          //   good.goodsPrice = parseFloat(good.saleSmallPrice).toFixed(2) || '0.00'
          // }else{
          //   good.goodsPrice = parseFloat(good.lastSmallPrice).toFixed(2) || '0.00'
          // }
          if(good.saleSmallPrice){
            good.goodsPrice = parseFloat(good.saleSmallPrice).toFixed(2)
          }else{
            good.goodsPrice = parseFloat(good.lastSmallPrice).toFixed(2)
          }

          good.usageAmount = 1
          good.usageId = 1
          good.usageName = '喷雾法'
        }

        good.totalPrice = parseFloat(good.goodsNumber * good.goodsPrice).toFixed(2)
        if (this.goodsCart.clientName === undefined || this.goodsCart.clientName.length < 1) {
          this.showChooseDialog()
        } else {
          let canAdd = true
          for (let i = 0; i < this.goodsCart.details.length; i++) {
            if (this.goodsCart.details[i].goodsId === good.goodsId) {
              // this.goodsCart.details.splice(i, 1, good)
              this.goodsCart.details[i].inventory = good.inventory
              canAdd = false
            }
          }
          if (canAdd) {
            if (this.isPesticide !== 2) {
              saleType = true
            } else {
              saleType = false
            }
            this.$nextTick(() => {
              this.$refs.dialogSaleOrder.openSaleGood(good, this.clientNames, flag, false, saleType)
            })
          }
          this.updateCat()
        }
      },
      editCart (item) {
        let flag = true
        let saleType = false
        if (this.isPesticide !== 2) {
          saleType = true
        } else {
          saleType = false
        }
        this.$nextTick(() => {
          this.$refs.dialogSaleOrder.openSaleGood(item, this.goodsCart.clientName, flag, false, saleType)
        })
      },
      //选择客戶
      handleClose (tag) {
        this.clientIds.splice(this.clientIds.indexOf(tag), 1)
      },
      showChooseDialog () {
        this.$nextTick(() => {
          let clientIds = this.clientIds
          //销货开单，type=2， 交回者type=3 处方开单，type=4
          let type = 2
          if (this.isPesticide !== 2) {
            type = 4
          }
          this.$refs.dialogClientChoose.getClientIds(clientIds, type)
        })
      },
      getClients (clientIds, clientNames, isPesticide, hljMode) {
        //console.log("clientIds, clientNames, isPesticide,hljMode",clientIds, clientNames,"isPesticide:", isPesticide,hljMode)
        this.clientNames = clientNames
        this.clientIds = clientIds
        this.isPesticide = isPesticide
        if (!Tool.isEmpty(SessionStorage.get(CODE_URL_LIST))) {
          this.codeUrlList = SessionStorage.get(CODE_URL_LIST)
        }

        // console.log("SessionStorage.get(SALE_RECIPE_CURRENT)111111: ",SessionStorage.get(SALE_RECIPE_CURRENT))
        if (hljMode) {
          this.hljMode = hljMode
          SessionStorage.set(HLJ_MODE, this.hljMode)
        }

        if (this.isHLJ && !this.isDN && this.hljMode === 1) {
          this.colSpanLeft = 24
          this.colSpanRight = 0
        }
        if (this.isHLJ && this.hljMode === 2) {
          this.colSpanLeft = 9
          this.colSpanRight = 15
        }
        if (this.isPesticide === 2 && (!this.isHLJ || (this.isHLJ && this.isDN))) {
          this.saleMode = true
          this.showPage = true
          // this.goodsCart.recipeId = ''
          // this.goodsCart.recipeName = ''
          // this.goodsCart.recipeType = ''
          // this.goodsCart.recipeCategoryName = ''
          // this.goodsCart.type = ''
          // this.goodsCart.remark = ''
          // this.goodsCart.details = []
          // this.goodsCart.paymentAmount = 0
          // this.goodsCart.accountsPayable = 0
          // this.goodsCart.discountMoney = 0
          // this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
          this.$forceUpdate()
          this.getCategorys()
          this.getSearch(1)
        } else if (this.isPesticide === 1 && (!this.isHLJ || (this.isHLJ && this.isDN))) {
          // console.log("SessionStorage.get(SALE_RECIPE_CURRENT)2222: ",SessionStorage.get(SALE_RECIPE_CURRENT))
          this.saleMode = false
          //初始化处方数据:默认不显示选择商品页面
          this.recipeAddGoods = false
          this.showPage = true
          this.goodsCart.recipeId = ''
          this.goodsCart.recipeName = ''
          this.goodsCart.recipeType = ''
          this.goodsCart.type = ''
          this.goodsCart.recipeCategoryName = ''
          this.goodsCart.details = []
          this.goodsCart.remark = ''
          this.goodsCart.paymentAmount = 0
          this.goodsCart.accountsPayable = 0
          this.goodsCart.discountMoney = 0
          this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
          /*this.saleMode=false
          this.recipeAddGoods=false*/

          this.getCategorys()
          this.getSearch(1)
          this.$forceUpdate()
        } else if (this.isPesticide === 3 && (!this.isHLJ || (this.isHLJ && this.isDN))) {
          // console.log("SessionStorage.get(SALE_RECIPE_CURRENT)3333: ",SessionStorage.get(SALE_RECIPE_CURRENT))
          this.currentRecipe = SessionStorage.get(SALE_RECIPE_CURRENT)
          // console.log("currentRecipe: ",this.currentRecipe)
          this.goodsCart = SessionStorage.get(SALE_RECIPE_DEAL_CART)
          this.recipeAddGoods = false
          this.showPage = false
          if (Tool.isNotEmpty(SessionStorage.get(SALE_RECIPE_DEAL_GOODS))) {
            this.recipeDealGoodsTemp = SessionStorage.get(SALE_RECIPE_DEAL_GOODS)
            if (Tool.isNotEmpty(SessionStorage.get(SALE_RECIPE_DEAL_GOODS_DEL))) {
              this.recipeDealGoodsDel = SessionStorage.get(SALE_RECIPE_DEAL_GOODS_DEL)
              for (let j = 0; j < this.recipeDealGoodsDel.length; j++) {
                for (let i = 0; i < this.recipeDealGoodsTemp.length; i++) {
                  if (this.recipeDealGoodsTemp[i].goodsId === this.recipeDealGoodsDel[j]) {
                    this.recipeDealGoodsTemp.splice(i, 1)
                  }
                }
              }
            }
            this.checkRecipe(this.currentRecipe, true)
          } else {
            this.checkRecipe(this.currentRecipe, true)
          }
        } else if (this.isPesticide === 4 && (!this.isHLJ || (this.isHLJ && this.isDN))) {
          this.recipeAddGoods = true
          this.showPage = true
          this.saleMode = false
          if (Tool.isNotEmpty(SessionStorage.get(SALE_RECIPE_DEAL_GOODS))) {
            this.recipeDealGoodsTemp = SessionStorage.get(SALE_RECIPE_DEAL_GOODS)
          }
          this.getSearch(1)
        } else if (this.isHLJ && !this.isDN && this.hljMode === 1) {
          this.showPage = false
          this.saleMode = false

          this.$forceUpdate()
          //console.log("黑龙江农药开单")
        } else if (this.isHLJ && (this.hljMode === 2 || this.isDN)) {
          //console.log("黑龙江其他商品开单")
          this.saleMode = true
          this.showPage = true
          // this.goodsCart.recipeId = ''
          // this.goodsCart.recipeName = ''
          // this.goodsCart.recipeType = ''
          // this.goodsCart.recipeCategoryName = ''
          // this.goodsCart.type = ''
          // this.goodsCart.remark=''
          // this.goodsCart.details = []
          // this.goodsCart.paymentAmount=0
          // this.goodsCart.accountsPayable=0
          // this.goodsCart.discountMoney=0
          // this.goodsCart.creditMoney=parseFloat(0).toFixed(2)
          // this.$forceUpdate()
          this.getCategorys()
          this.getSearch(1)
        }
        this.goodsCart.orderType = (this.isPesticide === 2 ? 0 : 1)
        this.goodsCart.clientId = this.clientIds
        this.goodsCart.clientName = this.clientNames

        SessionStorage.set(SALE_RECIPE_TYPE, this.isPesticide)
        if (this.isPesticide === 1) {
          SessionStorage.set(SALE_RECIPE_DEAL_CART, this.goodsCart)
        } else if (this.isPesticide === 2) {
          SessionStorage.set(SALE_CART, this.goodsCart)
          SessionStorage.remove(SALE_RECIPE_DEAL_CART)
        }

      },
      //购物车
      addSaleCart (good) {
        good.added = true
        let _this = this
        _this.goodsCart.details.push(good)
        //更新是否显示施用范围
        if (_this.goodsCart.details.some(item => (item.categoryId.toString()[0] == 2 || item.categoryId.toString()[0] == 7))) {
          _this.showRange = true
        } else {
          _this.showRange = false
        }
        _this.goodsCart.num = _this.goodsCart.details.length
        _this.goodsCart.accountsPayable = 0
        for (let i = 0; i < _this.goodsCart.details.length; i++) {
          _this.goodsCart.details[i].totalPrice = parseFloat(_this.goodsCart.details[i].totalPrice)
          _this.goodsCart.accountsPayable += _this.goodsCart.details[i].totalPrice
        }
        _this.goodsCart.paymentAmount = _this.goodsCart.accountsPayable
        _this.goodsCart.discountMoney = 0
        _this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
        _this.realPaymentMax = _this.goodsCart.accountsPayable
        if (good.codeUrl) {
          this.codeUrlList.push({
            goodsId: good.goodsId,
            codeUrl: good.codeUrl
          })
          SessionStorage.set(CODE_URL_LIST, this.codeUrlList)
        }

        if (this.isPesticide === 2 || this.isHLJ) {
          for (let i = 0; i < this.recipeDealGoods.length; i++) {
            if (this.recipeDealGoods[i].goodsId === good.goodsId) {
              this.recipeDealGoods[i].added = true
            }
          }
          SessionStorage.set(SALE_CART, _this.goodsCart)
          if (!this.isHLJ || this.isHLJ && (this.hljMode == 2 || this.isDN)) {
            this.getSearch(this.currentPage)
          }
          // console.log("this.recipeDealGoods:",this.recipeDealGoods)
          // console.log("get---",SessionStorage.get(SALE_CART))
          this.updateCat()
        } else if (this.isPesticide === 3) {
          for (let i = 0; i < this.recipeDealGoods.length; i++) {
            if (this.recipeDealGoods[i].goodsId === good.goodsId) {
              this.recipeDealGoods.splice(i, 1, good)
            }
          }
          SessionStorage.set(SALE_RECIPE_DEAL_GOODS, this.recipeDealGoods)
          SessionStorage.set(SALE_RECIPE_DEAL_CART, this.goodsCart)
          SessionStorage.set(SALE_RECIPE_DEAL_GOODS_DEL, this.recipeDealGoodsDel)
        } else if (this.isPesticide === 4) {
          good.suggestUsageCount = 0
          good.added = true
          for (let i = 0; i < this.recipeDealGoods.length; i++) {
            if (this.recipeDealGoods[i].goodsId === good.goodsId) {
              this.recipeDealGoods[i].added = true
            }
          }
          for (let i = 0; i < this.recipeGoodsList.length; i++) {
            if (this.recipeGoodsList[i].goodsId === good.goodsId) {
              this.recipeGoodsList.splice(i, 1, good)
            }
          }
          let pushFlag = false
          for (let i = 0; i < this.recipeDealGoods.length; i++) {
            if (this.recipeDealGoods[i].goodsId === good.goodsId) {
              pushFlag = true
              break
            }
          }
          // console.log("recipeDealGoods: ",this.recipeDealGoods)
          if (!pushFlag) {
            // this.recipeDealGoods.push(good)
            //如果添加已删除的商品，这里将已删除的缓存更新
            if (this.recipeDealGoodsDel.indexOf(good.goodsId) !== -1) {
              this.recipeDealGoodsDel.splice(this.recipeDealGoodsDel.indexOf(good.goodsId), 1)
            }
            SessionStorage.set(SALE_RECIPE_DEAL_GOODS_DEL, this.recipeDealGoodsDel)
          }
          SessionStorage.set(SALE_RECIPE_DEAL_GOODS, this.recipeDealGoods)
          SessionStorage.set(SALE_RECIPE_DEAL_CART, this.goodsCart)
          SessionStorage.set(SALE_RECIPE_DEAL_GOODS_DEL, this.recipeDealGoodsDel)
        } else {
          if (!this.isHLJ || this.isHLJ && (this.hljMode == 2 || this.isDN)) {
            this.getSearch(this.currentPage)
          }

        }
      },
      editSaleCart (good) {
        this.goodsCart.accountsPayable = 0
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          if (good.goodsId === this.goodsCart.details[i].goodsId) {
            this.goodsCart.details.splice(i, 1, good)
          }
          this.goodsCart.details[i].totalPrice = parseFloat(this.goodsCart.details[i].totalPrice)
          this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
        }
        this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        this.goodsCart.discountMoney = 0
        this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
        this.realPaymentMax = this.goodsCart.accountsPayable
        //更新是否显示施用范围
        if (this.goodsCart.details.some(item => (item.categoryId.toString()[0] == 2 || item.categoryId.toString()[0] == 7))) {
          this.showRange = true
        } else {
          this.showRange = false
        }
        SessionStorage.set(SALE_CART, this.goodsCart)
      },

      cartChange (item) {
        this.getSearch(1)
      },
      saveFinish (flag, type) {
        this.goodsCart.details = []
        this.goodsCart.saleDetailList = []
        this.goodsCart.num = this.goodsCart.details.length
        this.goodsCart.clientName = []
        this.goodsCart.clientId = []
        this.goodsCart.paymentAmount = 0.00
        this.goodsCart.accountsPayable = 0.00
        this.realPaymentMax = 0
        this.goodsCart.discountMoney = 0
        this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
        //处方
        this.goodsCart.recipeId = ''
        this.goodsCart.recipeName = ''
        this.goodsCart.recipeType = ''
        this.goodsCart.type = ''
        this.goodsCart.recipeCategoryName = ''
        this.goodsCart.details = []

        this.recipeDealGoods = []
        this.recipeDealGoodsDel = []
        this.recipeDealGoodsTemp = []
        this.recipeDealCart = {}

        if (type === 4) {//销货
          this.saleMode = true
          this.showPage = true
          this.isPesticide = 2
        } else if (type === 5) {//处方
          this.saleMode = false
          //初始化处方数据:默认不显示选择商品页面
          this.recipeAddGoods = false
          this.isPesticide = 1
        }
        SessionStorage.set(SALE_CART, this.goodsCart)
        SessionStorage.set(SALE_RECIPE_DEAL_CART, this.goodsCart)
        SessionStorage.set(SALE_RECIPE_TYPE, this.isPesticide)
        SessionStorage.remove(CODE_URL_LIST)
        this.codeUrlList = []

        if (!this.isHLJ || this.hljMode != -1 || (this.isHLJ && this.isDN)) {
          this.getSearch(1)
        }
        this.$forceUpdate()
        this.showChooseDialog()
      },
      saveStockCart (item) {
        item.goodsNumber = 1
        item.buyPackageLevel = 1
        item.goodsPrice = item.saleSmallPrice
        item.totalPrice = item.goodsPrice * item.goodsNumber
        item.usageAmount = 1
        item.usageId = 1
        item.usageName = '喷雾法'
        let pushFlag = true
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          if (this.goodsCart.details[i] === item.goodsId) {
            pushFlag = false
          }
        }
        if (pushFlag) {
          //console.log("++++++++++++")
          //this.goodsCart.details.push(item)
          if (this.isPesticide === 3 || this.isPesticide === 4) {
            for (let i = 0; i < this.recipeDealGoods.length; i++) {
              if (this.recipeDealGoods[i].goodsId === item.goodsId) {
                this.recipeDealGoods[i].inventory = item.inventory
                this.recipeDealGoods[i].status = item.status
                if (item.codeUrl) {
                  this.recipeDealGoods[i].codeUrl = item.codeUrl
                }
                this.recipeDealGoods[i].added = true
              }
            }
            if (this.isPesticide === 4) {
              for (let i = 0; i < this.recipeGoodsList.length; i++) {
                if (this.recipeGoodsList[i].goodsId === item.goodsId) {
                  if (item.codeUrl) {
                    this.recipeDealGoods[i].codeUrl = item.codeUrl
                  }
                  this.recipeGoodsList[i].added = true
                }
              }
            }

            //判断是否添加处方商品列表
            let pushFlag = false
            for (let i = 0; i < this.recipeDealGoods.length; i++) {
              if (this.recipeDealGoods[i].goodsId === item.goodsId) {
                pushFlag = true
                break
              }
            }

            this.addGood(item)
            /*if (!pushFlag) {
              this.addGood(item)
              if (item.suggestUsageCount && item.inventory >= item.suggestUsageCount) {
                item.goodsNumber = item.suggestUsageCount
              } else if (item.suggestUsageCount && item.inventory < item.suggestUsageCount && item.inventory > 0) {
                item.goodsNumber = item.inventory
              } else {
                item.suggestUsageCount = 0
                item.goodsNumber = 1
              }
              this.recipeDealGoods.push(item)
              //如果添加已删除的商品，这里将已删除的缓存更新
              if (this.recipeDealGoodsDel.indexOf(item.goodsId) !== -1) {
                this.recipeDealGoodsDel.splice(this.recipeDealGoodsDel.indexOf(good.goodsId), 1)
              }
              SessionStorage.set(SALE_RECIPE_DEAL_GOODS_DEL, this.recipeDealGoodsDel)
            }*/

          } else {
            if (this.isHLJ && !this.isDN && this.hljMode === 1) {
              console.log()
            } else {
              for (let i = 0; i < this.goods.length; i++) {
                if (this.goods[i].goodsId === item.goodsId) {
                  this.goods[i] = item
                }
              }
            }

          }
        }
        this.updateCat()
      },

      saveStockSaleCart (item) {
        let good = {}
        if (this.isPesticide === 3 || this.isPesticide === 4) {
          for (let i = 0; i < this.recipeDealGoods.length; i++) {
            if (this.recipeDealGoods[i].goodsId === item.goodsId) {
              if (item.codeUrl) {
                this.recipeDealGoods[i].codeUrl = item.codeUrl
              }
              this.recipeDealGoods[i].inventory = item.inventory
            }
          }

          if (this.isPesticide === 4) {
            for (let i = 0; i < this.recipeGoodsList.length; i++) {
              if (this.recipeGoodsList[i].goodsId === item.goodsId) {
                this.recipeGoodsList[i].inventory = item.inventory
                item.goodsPrice = this.recipeGoodsList[i].goodsPrice
              }
            }
          }
          this.addGood(item)
          this.updateCat()
        } else {
          for (let i = 0; i < this.goods.length; i++) {
            if (this.goods[i].goodsId === item.goodsId) {
              this.goods[i].inventory = item.inventory
              if (item.codeUrl) {
                this.goods[i].codeUrl = item.codeUrl
              }
              good = this.goods[i]
            }
          }
          if (Tool.isEmpty(good)) {
            good = item
          }
          let pushFlag = true
          if (this.isHLJ && !this.isDN && this.hljMode === 1) {
            for (let i = 0; i < this.goodsCart.details.length; i++) {
              if (this.goodsCart.details[i].goodsId === good.goodsId && good.inventory >= this.goodsCart.details[i].goodsNumber + 1) {
                this.goodsCart.details[i].goodsNumber += 1
                this.goodsCart.details[i].totalPrice = this.goodsCart.details[i].goodsNumber * this.goodsCart.details[i].goodsPrice
                if (good.codeUrl) {
                  this.codeUrlList.push({
                    goodsId: this.goodsCart.details[i].goodsId,
                    codeUrl: good.codeUrl
                  })
                }

                SessionStorage.set(CODE_URL_LIST, this.codeUrlList)
                pushFlag = false
                this.updateCat()
              }
            }

            //console.log("pushFlag: ",pushFlag,"----",good)
            if (pushFlag) {
              this.addGood(good)
            }
          } else {
            this.addGood(good)
            this.getSearch(1)
          }
        }
      },

      delCart (item) {
        let _this = this
        this.$toast.showConfirm(item.goodsName, function () {
            for (let j = 0; j < _this.goods.length; j++) {
              if (_this.goods[j].goodsId === item.goodsId) {
                _this.goods[j].added = false
              }
            }
            if (_this.isPesticide === 3) {
              for (let j = 0; j < _this.recipeDealGoods.length; j++) {
                if (_this.recipeDealGoods[j].goodsId === item.goodsId) {
                  _this.recipeDealGoods[j].added = false
                }
              }
            }
            for (let i = 0; i < _this.goodsCart.details.length; i++) {
              if (_this.goodsCart.details[i].goodsId === item.goodsId) {
                _this.goodsCart.details.splice(i, 1)
                _this.goodsCart.num = _this.goodsCart.details.length
                for (let i = 0; i < _this.codeUrlList.length; i++) {
                  if (_this.codeUrlList[i].goodsId === item.goodsId) {
                    _this.codeUrlList.splice(i)
                  }
                }
                //console.log("当前的codeUrlList：",_this.codeUrlList)
                SessionStorage.set(CODE_URL_LIST, _this.codeUrlList)
                _this.updateCat()
                if (_this.isPesticide === 3) {
                  SessionStorage.set(SALE_RECIPE_DEAL_CART, _this.goodsCart)
                  SessionStorage.set(SALE_RECIPE_DEAL_GOODS, _this.recipeDealGoods)
                } else {
                  SessionStorage.set(SALE_CART, _this.goodsCart)
                }

              }
            }
          }
        )

      },
      clearCart () {
        let _this = this
        this.$toast.showConfirm('清空购物车商品', function () {
            _this.goodsCart.details = []
            _this.goodsCart.num = _this.goodsCart.details.length
            //_this.goodsCart.clientName = []
            //_this.goodsCart.clientId = []
            _this.goodsCart.paymentAmount = 0.00
            _this.goodsCart.accountsPayable = 0.00
            _this.goodsCart.discountMoney = 0
            _this.goodsCart.creditMoney = parseFloat(0).toFixed()

            if (_this.isPesticide === 2) {
              SessionStorage.remove(SALE_CART)
              SessionStorage.set(SALE_CART, _this.goodsCart)
              _this.getSearch(1)
            } else {
              SessionStorage.remove(SALE_RECIPE_DEAL_CART)
              SessionStorage.remove(SALE_RECIPE_DEAL_GOODS_DEL)
              SessionStorage.remove(SALE_RECIPE_DEAL_GOODS)
              if (_this.isPesticide === 1 || _this.isPesticide === 4) {
                _this.getSearch(1)
              }
            }

          }
        )
      },
      saveCart () {
        if (this.goodsCart.clientName === undefined || this.goodsCart.clientName.length < 1) {
          this.showChooseDialog()
        } else if (this.goodsCart.num > 0) {
          for (let i = 0; i < this.goodsCart.details.length; i++) {
            if (this.goodsCart.details[i].inventory < 1) {
              this.goodsCart.details[i].stockFlag = true
              this.toStockOrder(this.goodsCart.details[i])
            } else {
              let type = 4
              if (this.isPesticide !== 2) {
                type = 5
              }
              let isNongYao = ''
              if (this.isHLJ) {
                isNongYao = this.hljMode
              }

              this.$nextTick(() => {
                this.$refs.dialogSaleOrderSure.sureOrders(this.goodsCart, type, isNongYao)
              })
            }
          }

        } else if (this.goodsCart.num < 1) {
          this.$message({
            message: '您还没有选择任何商品哦~',
            center: true,
            type: 'warning',
            offset: 150
          })
        }
      },
      //去进货
      toStockOrder (item, editGood) {
        //console.log("item---: ",item)
        let toastMessage = ''
        let inventoryCount = 0
        if (item.buyPackageLevel == 1) {
          inventoryCount = item.inventory + item.middleUnitName
        } else {
          let count = item.inventory / item.middleCount
          let indexCount = count.toString().indexOf('.')
          if (count.toString().indexOf('.') != -1) {
            if (count < 1) {
              inventoryCount = item.inventory + item.middleUnitName
            } else {
              inventoryCount = count.toString().substring(0, indexCount) + item.bigUnitName + ' 余' + (item.inventory - (parseInt(count.toString().substring(0, indexCount)) * item.middleCount)) + item.middleUnitName
            }
          } else {
            inventoryCount = count + item.bigUnitName
          }
        }
        if (item.inventory > 0 && item.suggestUsageCount) {
          toastMessage = '该商品当前库存数低于建议用量，' + '剩余库存：' + inventoryCount + ' ，建议用量：' + item.suggestUsageCount
        } else {
          toastMessage = '该商品库存不足，无法销货，' + '剩余库存：' + inventoryCount
        }
        let _this = this
        setTimeout(function () {
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.zIndex = '3000'
        }, 100)
        if (this.getAuthorityMenu('200103')) {
          this.$toast.showCodeConfirm(toastMessage, item.goodsName, '是否去进货', '去进货', function () {
              _this.$nextTick(() => {
                _this.$refs.dialogSaleOrder.dialogClose()
              })
              Loadings.show()
              _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/goBuyOrder?goodsId=' + item.goodsId)
                .then((response) => {
                  Loadings.hide()
                  let resp = response.data
                  _this.good = resp.data
                  _this.good.packingSpecification = _this.good.packageUnit
                  if (item.suggestUsageCount) {
                    if (item.inventory < item.suggestUsageCount) {
                      _this.good.goodsNumber = item.suggestUsageCount - item.inventory
                    } else {
                      _this.good.goodsNumber = 1
                    }
                  } else {
                    _this.good.goodsNumber = 1
                  }
                  if (_this.good.goodsNumber > 1) {
                    _this.good.buyPackageLevel = 1
                  } else {
                    _this.good.buyPackageLevel = 2
                  }

                  if (_this.good.packageLevel === 1) {
                    _this.good.goodsPrice = _this.good.lastSmallPrice
                  }
                  if (_this.good.packageLevel === 2) {
                    _this.good.goodsPrice = _this.good.lastBigPrice
                  }
                  _this.good.smallGoodsPrice = _this.good.lastSmallPrice
                  _this.good.bigGoodsPrice = _this.good.lastBigPrice
                  _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
                  if (item.codeUrl) {
                    _this.good.codeUrl = item.codeUrl
                  }

                  let supplierName = ''
                  if (_this.good.supplierList.length > 0) {
                    supplierName = _this.good.supplierList[0].supplierName
                  }
                  if (_this.good.supplierList.length < 1) {
                    this.$toast.error('该商品无可用供应商，请去商品管理关联供应商！', 'center')
                  } else {
                    if (editGood) {
                      let flag = false
                      _this.$nextTick(() => {
                        _this.$refs.dialogStockOrder.openStockGood(_this.good, supplierName, false, true, true)
                      })
                    } else {
                      _this.$nextTick(() => {
                        _this.$refs.dialogStockOrder.openStockGood(_this.good, supplierName, false, true, true)
                      })
                    }
                  }
                })
            }
          )
        } else {
          this.$toast.showAuth('当前登录用户没有访问权限，请重新登录！', 'center')
        }

      },
      getAuthorityMenu (id) {
        return Tool.authorityUserResources(id)
      },
      toNewGood () {
        this.updateCat()
        let type = 5
        this.$router.push({
          name: '/goodsManagerEdit',
          params: {
            type,
            isPesticide: this.isPesticide === 2 ? 2 : 1
          }
        })
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      //用药方法
      getUsageList () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/usage/list')
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.usageList = resp.data
          })
      },
      discountBlur (discountMoney) {
        //console.log("discountMoney: ",discountMoney)
      },
      //优惠金额更新
      discountMoneyChange (discountMoney) {
        //console.log("this.goodsCart.creditMoney: ",this.goodsCart.accountsPayable,"discountMoney",discountMoney)
        if (Tool.isEmpty(discountMoney) || discountMoney === undefined) {
          this.goodsCart.discountMoney = 0
        }
        if (discountMoney <= this.goodsCart.accountsPayable) {
          this.goodsCart.paymentAmount = this.goodsCart.accountsPayable - discountMoney
          this.realPaymentMax = this.goodsCart.accountsPayable - discountMoney
          //console.log("正常金额1")
          this.updateCredit()
        } else if (this.goodsCart.discountMoney === 0) {
          this.updateCredit()
        }
      },
      //实付金额更新
      paymentAmountChange (paymentAmount) {
        //console.log("paymentAmount: ",paymentAmount)
        if (Tool.isEmpty(paymentAmount)) {
          this.goodsCart.paymentAmount = 0
        }
        if (paymentAmount <= this.goodsCart.accountsPayable - this.goodsCart.discountMoney) {
          this.realPaymentMax = this.goodsCart.accountsPayable - this.goodsCart.discountMoney
          this.updateCredit()
          //console.log("正常金额2")
        } else if (paymentAmount > this.goodsCart.accountsPayable - this.goodsCart.discountMoney) {
          this.goodsCart.paymentAmount = this.goodsCart.accountsPayable - this.goodsCart.discountMoney

        } else if (this.goodsCart.paymentAmount === 0) {
          this.updateCredit()
        }
      },
      updateCredit () {
        let currentRange = this.goodsCart.accountsPayable - (this.goodsCart.discountMoney + this.goodsCart.paymentAmount)
        if (currentRange === 0) {
          this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
        } else if (currentRange > 0) {
          this.goodsCart.creditMoney = parseFloat(currentRange).toFixed(2)
        } else if (currentRange < 0) {
          this.goodsCart.creditMoney = parseFloat(0).toFixed(2)
          this.goodsCart.discountMoney = parseFloat(0).toFixed(2)
          this.goodsCart.paymentAmount = parseFloat(this.goodsCart.accountsPayable).toFixed(2)
        }
      },
      //视口
      changeWidth () {
        let width = $(window).width()

        setTimeout(function () {
          let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
          let clearCart = document.getElementsByClassName('clearCart')[0]
          let selectCart = document.getElementsByClassName('selectCart')
          let select1 = ''
          let select2 = ''
          for (let i = 0; i < selectCart.length; i++) {
            select1 = selectCart[i].childNodes[0].lastChild
            select2 = selectCart[i].childNodes[1].lastChild
            if (width >= 1900) {
              select1.style.width = '90px'
              select2.style.width = '50px'
            } else if (width < 1900 && width > 1669) {
              select1.style.width = '80px'
              select2.style.width = '50px'
            } else if (width <= 1669 && width > 1560) {
              select1.style.width = '75px'
              select2.style.width = '30px'
            } else if (width <= 1560 && width > 1460) {
              select1.style.width = '60px'
              select2.style.width = '30px'
            } else if (width <= 1460 && width > 1365) {
              select1.style.width = '50px'
              select2.style.width = '30px'
            } else {
              select1.style.width = '30px'
              select2.style.width = '30px'
              select1.style.fontSize = '12px'
              select2.style.fontSize = '12px'
            }
          }
          if (width >= 1619) {
            inputCoreDesc.style.width = '400px'
          }
          if (this.showClearBtn) {
            if (width > 1669) {
              clearCart.style.marginRight = '-100px'
            }
            if (width <= 1669) {
              clearCart.style.marginRight = '0px'
            }
            if (width < 1640) {
              clearCart.style.marginRight = '50px'
            }
            if (width < 1523) {
              clearCart.style.marginRight = '100px'
            }
            if (width < 1370) {
              clearCart.style.marginRight = '160px'
            }

          }

          if (width < 1619) {
            inputCoreDesc.style.width = '280px'
          }
          if (width < 1310) {
            inputCoreDesc.style.width = '250px'
          }
          if (width < 1240) {
            inputCoreDesc.style.width = '200px'
          }

        }, 0)
        this.triggerResize()

      },
      triggerResize () {
        let that=this
        $(window).on('resize', function () {
          let width = $(window).width()
          // console.log("width: ",width)
          // that.greenWidth=width*0.070

          that.$forceUpdate()
          let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
          let clearCart = document.getElementsByClassName('clearCart')[0]
          let selectCart = document.getElementsByClassName('selectCart')
          let select1 = ''
          let select2 = ''
          for (let i = 0; i < selectCart.length; i++) {
            select1 = selectCart[i].childNodes[0].lastChild
            select2 = selectCart[i].childNodes[1].lastChild
            if (width >= 1900) {
              select1.style.width = '90px'
              select2.style.width = '50px'
            } else if (width < 1900 && width > 1669) {
              select1.style.width = '80px'
              select2.style.width = '50px'
            } else if (width <= 1669 && width > 1560) {
              select1.style.width = '75px'
              select2.style.width = '30px'
            } else if (width <= 1560 && width > 1460) {
              select1.style.width = '60px'
              select2.style.width = '30px'
            } else if (width <= 1460 && width > 1365) {
              select1.style.width = '50px'
              select2.style.width = '30px'
            } else {
              select1.style.width = '30px'
              select2.style.width = '30px'
              select1.style.fontSize = '12px'
              select2.style.fontSize = '12px'
            }
          }

          if (width >= 1619) {
            inputCoreDesc.style.width = '400px'
          }

          if (this.showClearBtn) {
            if (width > 1669) {
              clearCart.style.marginRight = '-100px'
            }
            if (width <= 1669) {
              clearCart.style.marginRight = '0px'
            }
            if (width < 1640) {
              clearCart.style.marginRight = '50px'
            }
            if (width < 1523) {
              clearCart.style.marginRight = '100px'
            }
            if (width < 1370) {
              clearCart.style.marginRight = '160px'
            }
          }

          if (width < 1619) {
            inputCoreDesc.style.width = '280px'
          }
          if (width < 1310) {
            inputCoreDesc.style.width = '250px'
          }
          if (width < 1240) {
            inputCoreDesc.style.width = '200px'
          }
        }).trigger('resize')
      },

      //施用范围
      rangeEnter (useRange) {
        //console.log("施用范围：",useRange)
      },
    }
  }
</script>

<style scoped>
  .mainBody {
    padding: 10px;
  }

  .rowForm {
    margin-bottom: 0px;
  }

  .leftBody, .rightBody {
    background: #fafafa;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: dashed 1px #faecd8;
    min-height: 840px;
  }

  .topNav {
    float: left;
    margin-left: 10px;
    width: 100%;
  }

  .topNavRight {
    float: left;
    margin-left: 10px;
  }

  /*客戶*/
  .keyTags {
    float: left;
    margin-right: 8px;
    margin-bottom: 10px;
    line-height: 40px;
    height: 40px;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .keyTagsInput {
    float: left;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  .clearCart {
    float: right;
    right: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  /*购物车*/
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  .pay {
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
  }

  .pay .payLabel {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .priceInput {
    float: left;
  }

  /*备注*/
  .remarkLabel {
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;

  }

  .inputCoreDesc {
    float: left;
    width: 400px;
    margin-left: 28px;
  }

  .bottomBtn {
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 180px;
    margin-right: 10px;
  }

  .resetBtn {
    width: 180px;
  }

  .tipTag {
    position: relative;
    float: right;
  }

  .tagBox {
    background: #e92322;
    margin-top: -10px;
    margin-right: 10px;
    border-top-right-radius: 10px;
    padding-left: 5px;
    border-bottom-left-radius: 10px;
  }

  .tagImg {
    width: 30px;
    float: left;
    padding: 5px 0;
  }

  .tagTitle {
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 10px 10px 10px 5px;

  }

  /*右侧页面*/
  .categoryInput {
    float: left;
    width: 180px;
    margin-right: 10px;
  }

  .searchInput {
    width: 200px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 0;
    float: left;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*商品列表*/
  .el-col-4 {
    width: 16.4%;
  }

  .mainCard {
    margin-left: 5px;
    margin-top: 10px;
    min-height: 670px;
  }

  /deep/ .el-card__body {
    padding: 5px;
  }

  .goodCart {
    max-width: 230px;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  .imgBox{
    position: relative;
  }
  .innerImg{
    position: absolute;
    left: 2px;
    bottom: 0;
    width: 97.5%;
  }
  .cardImage {
    /*width: 150px;
    height: 150px;*/
    /*max-height: 145px;*/
    height: 145px;
    border-radius: 10px;
  }

  .desc .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .desc .descContent {
    line-height: 30px;
    font-size: 12px;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .descTag .descContentTag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descContentTag {
    padding-bottom: 8px;
  }

  .descTag .descContentTag:nth-of-type(2) {
    padding-top: 0;
  }

  .descTag .descContentTag /deep/ .el-tag {
    line-height: 25px;
    height: 25px;

  }

  .addCart {
    padding: 6px 12px;
    margin-bottom: 5px;
  }

  .inventoryTag {
    padding: 0 20px;
  }

  /deep/ .el-message {
  }

  /*已选*/
  .addGood {
    border: 1px solid #F56C6C;
    position: relative;
  }

  .checkedbox {
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: #F56C6C;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }

  .checkedboxPlugin {
    width: 80px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #e92322;
    color: #ffffff;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .inventoryWarning {
    color: #f8c375;
    margin-right: 3px;
    margin-left: 3px;
  }

  .scanCode {
    color: #fff;
    border: 0px solid #fff;
    position: absolute;
    outline: none;
    width: 300px;
    opacity: 0;
  }

  /*分页更新*/
  .pageCommon {

  }

  /*处方列表样式*/
  .cftype {
    float: left;
    margin-right: 10px;
    width: 120px;
  }

  .mainRecipeForm {
    margin-top: 10px;
  }

  .recipeCheckMenu {
    padding: 8px 16px;
  }

  /*商品处理页面样式*/
  .dealTip {
    margin-top: 30px;
  }

  .dealTip /deep/ .el-divider__text {
    background: #fafafa;
    padding: 0 10px;
  }

  .dealTip .dealTipIcon {
    font-size: 16px;
    color: #409EFF;
    top: -2px;
  }

  .dealTip .dealTipTitle {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    margin-top: 5px;
    color: #b4b4b4;
  }

  .dealTip .dealTipTitleAdd {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    margin-top: 5px;
    color: #b4b4b4;
  }

  /*暂无商品样式*/
  .noGoodsBox {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
  }

  /*选择商品*/
  .selectGoodsBox {
    position: fixed;
    right: 60px;
    bottom: 130px;
  }

  .selectRecipeBox {
    position: fixed;
    right: 60px;
    bottom: 240px;
  }

  .selectGoodsBoxBig {
    position: fixed;
    right: 60px;
    bottom: 150px;
  }

  .selectGoodsBtnBig {
    background: #e92322;
    width: 40px;
    height: 160px;
    border-radius: 10px;
    cursor: pointer;
  }

  .selectGoodsBtn {
    background: #e92322;
    width: 40px;
    height: 102px;
    border-radius: 10px;
    cursor: pointer;
  }

  .selectRecipeBtn {
    background: #e92322;
    width: 40px;
    height: 105px;
    border-radius: 10px;
    cursor: pointer;
  }

  .selectGoodsIcon {
    font-size: 20px;
    width: 40px;
    margin: 10px 0 auto;
    color: #ffffff;
  }

  .selectGoodsTitle {
    color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    width: 50px;
    writing-mode: vertical-lr; /*从左向右 从右向左是 writing-mode: vertical-rl;*/
    writing-mode: tb-lr; /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
  }

  /*删除处方商品*/
  .delGoods {
    position: absolute;
    right: -10px;
    top: -10px;
  }

  .delGoods {
    padding: 18px !important;
    border-radius: 15px !important;
  }

  .delGoods /deep/ .el-icon-delete {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  /*优化扫码*/
  .toastMessage {
    cursor: pointer;
  }

  .topPage {
    margin-top: -20px;
  }

  .noBottom {
    padding-bottom: 0;
  }

  /*购物车切换*/
  .selectCart {
    line-height: 1;

  }

  .selectCart /deep/ .el-radio-button .el-radio-button__inner {
    height: 28px;
    border-left: unset;
    border: unset;
    padding: 0;
    font-size: 12px;
    line-height: 28px;
  }

  .selectCart /deep/ .el-radio-button:first-child .el-radio-button__inner {
    background-color: #F56C6C;
    color: #ffffff;
    margin-right: 2px;
    width: 80px;
  }

  .selectCart /deep/ .el-radio-button:first-child .el-radio-button__inner:focus, .selectCart /deep/ .el-radio-button:first-child .el-radio-button__inner:hover {
    background: #f78989;
    border-color: #f78989;
    color: #FFFFFF;
  }

  .selectCart /deep/ .el-radio-button:last-child .el-radio-button__inner {
    background-color: #E6A23C;
    color: #ffffff;
    width: 40px;
  }

  .selectCart /deep/ .el-radio-button:last-child .el-radio-button__inner:focus, .selectCart /deep/ .el-radio-button:last-child .el-radio-button__inner:hover {
    background: #ebb563;
    border-color: #ebb563;
    color: #FFFFFF;
  }

  .selectCart /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: unset;
  }

  .payDebt {
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 5px;
  }

  .payRemark {
    display: inline-block;
    float: left;
    margin-left: 20px;
  }

  .payDebt .payLabel {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .debtMoneyText {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-left: 30px;
  }

  .debtMoneyDesc {
    display: inline-block;
    background: #f4f4f5;
    border: 1px dashed #e9e9eb;
    border-radius: 10px;
    padding: 3px 8px 3px 8px;
    margin-top: 3px;
    margin-left: 10px;
    color: #878787;
  }

  .debtMoneyWarning {
    /*font-size: 20px;*/
    color: #e92322;
  }

  /deep/ .el-table__fixed-body-wrapper {
    top: 59px !important;
  }

  .isDisplay {
    display: block !important;
  }

  .unDisplay {
    display: none !important;
  }

  .overFlowText {
    width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #b4b4b4;
    display: block;
    /*padding-top: 5px;*/
  }

  .headerHight /deep/ .el-table__header-wrapper {
    height: 60px;
  }
</style>
